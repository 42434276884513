#expertsearch {
    legend {
        float: left;
        font-size: 19px;
        vertical-align: middle;
    }
    select {
        height: auto;
        margin: 0 20px 0 20px;
        background: #ededed;
        border: none;
        font-size: 18px;
        text-transform: none;
        width: 150px;
        line-height: 32px;
        padding: 5px 5px 3px 5px;
        border-radius: 5px;
        float: left;
    }
    input {
        width: 300px;
        max-width: none;
        margin-top: 0;
        padding: 4px 5px 2px 5px;
        border: solid 1px #dcdcdc;
        vertical-align: middle;
    }
    input[type='text'] {
        margin: 2px;
        padding: 4px 5px;
        font-size: 15px;
    }
    #id_q {
        float: left;
    }
    button {
        margin: 2px 0 0 20px;
        padding: 2px 20px;
        background: #9c7928;
        border-radius: 3px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-transform: none;
        line-height: 19px;
        float: none;
        display: inline-block;
    }
}

.featuredExperts {
    border-top: 5px solid #dcdcdc;
    margin-top: 40px;
    padding-top: 20px;
    h2 {
        text-align: center;
        padding-bottom: 20px;
    }
    .expertList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }
    }
    .columns {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 10px;
        @media (max-width: 400px) {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        img {
            @media (max-width: 400px) {
                width: 100%;
                height: auto;
            }
        }
        p {
            margin: 0;
            padding-top: 0;
            line-height: 1.5rem;
            font-size: 1.1rem;
            padding-bottom: 5px;
            a {
                color: $black;
                font-size: 1.4rem;
                text-decoration: underline;
                font-family: $libreBaskerville;
                font-weight: 400;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
.paginationSection {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 10px;
    font-weight: bold;
    margin: 20px 0;
    @media (max-width: 600px) {
        text-align: center;
        grid-template-columns: 1fr;
    }
    ul {
        list-style-type: none;
        @media (max-width: 600px) {
            margin: 0;
            padding: 0;
        }
        li {
            display: inline;
            border-right: 1px solid $darkgold;
            &:last-of-type {
                border-right: 0;
            }
            a {
                color: $darkgold;
                margin: 0 4px 0 7px;
                padding: 0;
                cursor: pointer;
                @media (max-width: 600px) {
                    margin: 0 4px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.expert-detail-page {
    .singleExpert {
        display: grid;
        grid-template-columns: 175px 1fr;
        grid-gap: 30px;
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
            height: auto;
        }
        p {
            margin: 0 0 20px 0;
            line-height: 1.6rem;
            @media #{$break600} {
                margin-left: 0;
            }
        }
    }
    .twelve.columns {
        width: 63%;
        margin-right: 5%;
        float: none;
        @media #{$break1200} {
            width: 56%;
        }
        @media #{$break1000} {
            width: auto;
            margin-right: 0;
        }
        @media #{$break600} {
            text-align: center;
        }
    }
    .eleven.columns {
        padding-bottom: 15px;
    }
    .row.ourExperts {
        h2 {
            font-size: 30px;
            @media #{$break600} {
                margin-bottom: 0;
                text-align: center;
            }
        }
    }
    .singleExpert {
        .five.columns {
            @media #{$break600} {
                width: 250px;
                margin: -10px auto 10px auto;
                float: none;
            }
        }
        .eleven.columns {
            @media #{$break600} {
                width: auto;
            }
        }
    }
    .four.columns.aside {
        width: 29%;
        margin-left: 660px;
        position: fixed;
        top: 170px;
        @media #{$break1100} {
            margin-left: 61%;
        }
        @media #{$break1000} {
            display: none !important;
        }
        .catalog {
            margin-bottom: 30px;
            font-family: $opensans;
            text-align: left;
            img {
                width: 120px;
                margin-right: 15px;
            }
            .catalog-text {
                width: 140px;
                margin-top: -10px;
                display: inline-block;
                vertical-align: top;
                h3 {
                    font-size: 22px;
                    font-weight: bold;
                    text-align: left;
                    text-transform: none;
                }
                p {
                    padding: 0 !important;
                    font-size: 16px;
                    line-height: 19px;
                }
                .button.black.tiny {
                    width: 100%;
                    background: $gold;
                    font-size: 17px;
                    font-weight: normal;
                    text-transform: none;
                    line-height: 27px;
                }
            }
        }
        .sidebar-newsletter {
            width: 280px;
            h2 {
                margin-left: 20px;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0;
                text-align: left;
                text-transform: none;
                display: inline-block;
            }
            p {
                color: #333;
                font-family: $opensans;
            }
            button {
                margin-top: 20px;
                padding: 5px 35px;
                background: $gold;
                border: none;
                border-radius: 5px;
                color: $white;
                font-size: 16px;
                float: right;
            }
        }
        .socialConnect {
            margin-top: 20px;
            .catalog-newsletter {
                display: none;
            }
            a.connect {
                margin-bottom: 8px;
            }
        }
    }
    #tourMenus {
        width: auto;
        padding: 15px 0;
        h3 {
            color: #333;
            font-size: 30px;
            text-transform: none;
        }
        .tourTestimonials {
            margin-bottom: 50px;
            .startQuote,
            .endQuote {
                width: 32px;
                font-size: 70px;
            }
            blockquote {
                text-align: left;
                clear: both;
                p {
                    color: #777;
                    font-size: 25px;
                    font-style: italic;
                    line-height: 35px;
                    &:before,
                    &:after {
                        content: '\201C';
                        margin: 10px 10px -10px 0;
                        color: #d0d0d0;
                        font-family: serif;
                        font-size: 70px;
                        float: left;
                        vertical-align: bottom;
                        vertical-align: -webkit-baseline-middle;
                        vertical-align: -moz-baseline-middle;
                    }
                    &:after {
                        content: '\201D';
                        float: none;
                    }
                }
            }
            .traveler {
                width: auto;
                margin: -20px 0 50px;
                font-size: 19px;
                float: right;
            }
            .ultrathinLineFull:last-of-type {
                display: none;
            }
        }
        .row.leftMenu {
            margin: 50px 0 30px 0;
            h3 {
                margin-bottom: 20px;
            }
            ul li {
                color: #777;
                font-family: $opensans;
                font-size: 17px;
                font-weight: normal;
                text-align: left;
            }
        }
    }
    .row.tourDetails {
        margin: 30px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            display: block;
        }
        h3 {
            margin-bottom: 12px;
            font-size: 30px;
            grid-column: span 3;
            @media (max-width: 800px) {
                grid-column: span 2;
            }
        }
        .row.upcomingTours {
            @media (max-width: 600px) {
                margin-bottom: 50px;
            }
            .oneOfThree.columns {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .twoOfThree.columns {
                width: 100%;
                font-family: $opensans;
                .bold.text18 {
                    margin-bottom: 15px;
                    font-size: 26px;
                    font-weight: normal;
                    @media (max-width: 600px) {
                        margin-top: 10px;
                    }
                }
                p.tour-price.text16 {
                    font-size: 17px;
                    font-weight: bold;
                }
            }
        }
        .boldLineFullGray {
            grid-column: span 3;
            @media (max-width: 800px) {
                grid-column: span 2;
            }
        }
        .experts-qa {
            grid-column: span 3;
            @media (max-width: 800px) {
                grid-column: span 2;
            }
            p {
                margin: 30px 0;
                font-family: $opensans;
                font-size: 17px;
                line-height: 27px;
                text-align: left;
            }
        }
    }
}
