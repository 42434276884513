$pink: #e92b94;

.newsletter {
    label {
        display: block;
        font-weight: bold;
        .required {
            color: #c00;
        }
    }
    input[type='text'],
    input[type='email'] {
        width: 100%;
        padding: 4px 5px;
        font-size: 1.1rem;
        border: 1px solid #959595;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .emaillookup {
        padding: 15px 0;
        margin-top: 40px;
        margin-bottom: 20px;
        p {
            margin: 0;
            a {
                text-decoration: underline;
            }
        }
    }
    button {
        border: 1px solid #000;
        padding: 6px 20px;
        background: #000;
        color: #fff;
        border-radius: 5px;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#3e3e3e),
            to(#111111)
        );
        background: -moz-linear-gradient(top, #3e3e3e, #111111);
        text-transform: uppercase;
        margin-top: 20px;
    }
    .sticky {
        display: none;
    }
}

.field-group-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 20px;
    gap: 10px;
    input {
        width: 100%;
    }
}
.address_field {
    margin-bottom: 20px;
}

.email-src-add {
    background: #fff3cd;
    padding: 0 10px;
    font-weight: 700;
    margin-top: 30px;
    p {
        margin: 0;
        padding: 0;
    }
}

.preferenceGrid {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
    margin-bottom: 20px;
    input[type='checkbox'] {
        width: 25px;
        height: 25px;
    }
    label {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    p {
        margin-top: 5px;
    }
    &.checkGrid {
        align-items: center;
        grid-template-columns: 30px 1fr;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.contact-block {
    background: #eee;
    color: #000;
    font-weight: bold;
    padding: 12px;
    margin-bottom: 20px;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        margin: 0;
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.gold {
    color: $darkgold;
    text-decoration: underline;
}

.acousticSearch {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 10px;
    input[type='email'] {
        padding: 8px;
        margin-left: 10px;
    }
    input[type='submit'] {
        margin-top: 0;
    }
}

.preferenceSave,
button.preferenceSave {
    background: $black;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 30px;
    border: 0;
    margin-top: 10px;
    border-radius: 0;
}
