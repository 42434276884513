.readingList {
    .fourcol-image {
        text-align: center;
        h3 {
            padding: 5px 0 10px 0;
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        a {
            button {
                font-size: 0.8rem;
                font-weight: bold;
                width: 135px;
                height: 27px;
                color: $white;
                border-radius: 5px;
                background: $darkgold;
                text-decoration: none;
                text-transform: uppercase;
                cursor: pointer;
                display: block;
                border: none;
                outline: none;
                margin: 10px auto 6px;
                &:hover {
                    background: $black;
                }
            }
        }
    }
}
