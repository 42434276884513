body.student-travel {
    .contentBody {
        .cms-plugin {
            line-height: 1.7rem;
        }
        h2 {
            line-height: 2.2rem !important;
        }
        blockquote {
            color: #545454;
            padding: 15px 0 20px 0;
            margin: 0;
            clear: both;
            &::before {
                content: '“';
                font-family: $libreBaskerville;
                font-size: 5.5rem;
                float: left;
                vertical-align: -webkit-baseline-middle;
                margin: 50px 30px 0 0;
                height: 50px;
                color: #545454;
                @media (max-width: 400px) {
                    height: 180px;
                }
            }
        }
        .studentAdventures-tourList {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
            margin-top: 20px;
            margin-bottom: 40px;
            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 800px) {
                grid-template-columns: 1fr;
            }
            .column {
                h3 {
                    font-size: 1.4rem;
                    margin-bottom: 8px;
                    font-weight: 700;
                    a {
                        color: $black;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .description {
                    margin: 0;
                    font-weight: 700;
                    line-height: 1.7rem;
                }
                .dates {
                    margin-top: 8px;
                    color: #757575;
                    margin-bottom: 20px;
                }
                hr {
                    background: #e7e7e7;
                    height: 4px;
                    border: 0;
                }
            }
            img {
                width: 100%;
                height: auto;
                margin-bottom: 10px;
            }

            p {
                a {
                    color: $darkgold;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
