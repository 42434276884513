.virtual-tour {
    padding-bottom: 100px;
    @media (max-width: 1000px) {
        padding: 20px 20px 100px;
    }

    .containerFixed {
        margin-top: -70px;
        @media (max-width: 1000px) {
            margin-top: 40px;
        }
    }

    h2 {
        margin: 30px 0 20px;
        padding-top: 20px;
        color: #000;
        font-size: 25px;
        font-weight: bold;
        clear: both;
        &.reduce-gap {
            margin-top: -20px;
        }
    }
    h3 {
        a {
            color: $black;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    p {
        margin-bottom: 20px;
        font-size: 16px;
        a {
            color: $darkgold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    img {
        max-width: 100%;
    }

    .billboard {
        h2 {
            font-size: 30px;
        }

        img {
            width: 100%;
        }
    }

    .tourTypesGroup {
        h2 {
            margin-top: 0;
            padding-top: 0;
        }

        img {
            display: block;
        }
    }
}

.imageLeft-textRight {
    clear: both;
    @media (max-width: 600px) {
        margin-bottom: 50px;
    }

    img {
        width: 300px;
        margin: 0 30px 30px 0;
        float: left;
        @media (max-width: 600px) {
            width: 100%;
            float: none;
            display: block;
        }
    }

    strong {
        font-size: 16px;
    }
}

.threecol-image,
.fourcol-image {
    width: 300px;
    margin: 20px 5px;
    display: inline-block;
    vertical-align: top;
    @media (max-width: 1000px) {
        width: 31%;
        margin: 1%;
    }
    @media (max-width: 600px) {
        width: 100%;
        margin: 20px auto;
    }

    img {
        width: 100%;
        margin-bottom: 15px;
        display: block;
    }

    strong {
        font-size: 16px;
        line-height: 1;
    }
}

.fourcol-image {
    width: 220px;
    @media (max-width: 1000px) {
        width: 22%;
        margin: 1%;
    }
    @media (max-width: 600px) {
        width: 100%;
        margin: 20px auto;
    }

    img {
        margin-bottom: 10px;
    }
}
