.shipBodyColumns {
    margin-top: 20px;
    margin-bottom: 40px;
    &.transportationDetail {
        .transportationImage {
            width: 100%;
            height: auto;
        }
        .initialImage {
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 190px;
            height: auto;
        }
        .tourList {
            grid-template-columns: 1fr 1fr;
        }
        .shipRelatedtours {
            margin: 40px 0;
            padding-top: 20px;
            border-top: 5px solid #d1d1d1;
        }
    }
    .shipList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin-bottom: 40px;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }
        .shipListContainer {
            display: grid;
            grid-template-columns: 90px 1fr;
            grid-gap: 15px;
        }
        img {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
        }
        h3 {
            margin-top: 0;
            font-size: 1.2rem;
            margin-bottom: 0;
            padding-bottom: 0;
            a {
                color: $black;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        p {
            margin-top: 4px;
            a {
                color: $darkgold;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.deckplanImage {
    padding: 40px 0;
    img {
        width: 100%;
        height: auto;
    }
}
