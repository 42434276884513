.contentBody {
    margin-bottom: 60px;
}
.contentBodyColumns {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 40px;
    margin-top: 20px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
    margin-bottom: 60px;
    p {
        line-height: 1.6rem;
    }
    a {
        text-decoration: underline;
        color: $darkgold;
        &:hover {
            text-decoration: none;
        }
    }
    &.faqs {
        ol {
            border-top: 4px solid #d1d1d1;
            border-bottom: 4px solid #d1d1d1;
            padding-top: 20px;
            padding-bottom: 20px;
            li {
                line-height: 1.6rem;
                a {
                    color: $darkgold;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        h3 {
            color: $black;
            padding-top: 30px;
            font-weight: normal;
            &:first-of-type {
                border-top: 0;
            }
        }
    }
    .sticky {
        position: sticky;
        top: 220px;
        .catalog-text {
            h3 {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

.contentBody form {
    margin: 20px auto;
    .field {
        margin-bottom: 10px;
    }
    label {
        display: block;
        font-weight: bold;
    }
    select {
        height: 32px;
        line-height: 32px;
        margin-bottom: 4px;
        padding: 5px 5px 3px 5px;
        font-size: 14px;
        font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
            sans-serif;
        border-top: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #c5c5c5;
        border-bottom: 1px solid #c5c5c5;
        border-radius: 5px;
        background-color: #f5f5f5;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#fefefe),
            to(#e6e6e6)
        );
        background: -moz-linear-gradient(top, #fefefe, #e6e6e6);
    }
    input,
    textarea {
        width: 98%;
        margin: 2px 0 5px 0;
        padding: 4px 5px;
        border: solid 1px #bbb9b9;
        font-size: 15px;
        font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
            sans-serif;
    }
    textarea {
        border: solid 1px #bbb9b9;
        box-shadow: none;
    }
    input[type='radio'],
    input[type='checkbox'],
    .no-bullets label {
        width: auto;
        display: inline-block;
    }
    .required {
        color: #d42930;
        font-size: 0.8rem;
    }
    .form-dropdown {
        margin: 5px 0 30px;
        padding: 5px 15px;
        background: #d0d0d0;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        cursor: pointer;
        .fa-sort-down {
            margin: 0px 10px 7px 2px;
            vertical-align: top;
        }
    }
    .button {
        margin: 0;
        border-radius: 5px;
        background: #deb659;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#deb659),
            to(#ad872e)
        );
        text-transform: uppercase;
        line-height: 1.5;
    }
}

.activityLevels {
    margin-top: 20px;
    h3 {
        color: $black;
        padding-top: 30px;
        border-top: 5px solid #d1d1d1;
    }
    .levels {
        display: grid;
        grid-template-columns: 115px 1fr;
        grid-gap: 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        .level {
            img {
                width: 100%;
                height: auto;
            }
            p {
                &:first-of-type {
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }
    }
}
