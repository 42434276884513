.sidebar-catalog {
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-gap: 15px;
    img {
        width: 100%;
        height: auto;
    }
    h3 {
        margin-top: 0;
        padding-top: 0;
        color: $black;
        padding-bottom: 0;
        font-size: 1.3rem;
    }
    p {
        margin: 5px 0;
    }
    .button {
        width: 130px;
        height: 25px;
        padding-top: 4px;
        background: $darkgold;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;
        line-height: 1.4;
        color: #fff;
        border-radius: 5px;
        display: block;
        border: none;
        outline: none;
        margin: 10px auto 6px 0;
        text-align: center;
        text-decoration: none;
        &:hover {
        }
    }
}

.sidebar-newsletter {
    padding-top: 50px;
    h3 {
        margin-top: 0;
        padding-top: 0;
        color: $black;
        padding-bottom: 0;
        font-size: 1.3rem;
    }
    p {
        margin: 5px 0;
    }
    .button {
        width: 130px;
        height: 25px;
        padding-top: 4px;
        background: $darkgold;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;
        line-height: 1.4;
        color: #fff;
        border-radius: 5px;
        display: block;
        border: none;
        outline: none;
        margin: 10px auto 6px 0;
        text-align: center;
        text-decoration: none;
    }
}

.boldLineFullGray {
    width: 100%;
    height: 5px;
    background: #dcdcdc;
}

.slideshow {
    max-width: 960px;
    height: 439px;
    max-height: 439px;
    margin: auto;
    position: relative;
    overflow: hidden;

    @media (max-width: 1000px) {
        height: 43vw;
    }

    @media (max-width: 600px) {
        height: 60vw;
    }

    &.slideshowMini {
        max-width: 420px;
        height: 280px;
        margin: 0;
        @media (max-width: 800px) {
            width: 100%;
            max-width: none;
            height: 52vw;
        }
        .cycle-prev,
        .cycle-next {
            width: 30px;
            height: 60px;
            opacity: 1;
            top: 45%;
            @media (max-width: 800px) {
                width: 4vw;
                height: 9vw;
                top: 40%;
            }
            svg {
                font-size: 20px;
                @media (max-width: 1000px) {
                    font-size: 5vw;
                }
            }
        }
        .slide {
            @media (max-width: 800px) {
                left: 0;
                right: 0;
            }
        }
    }

    .cycle-prev,
    .cycle-next {
        display: none;
        width: 50px;
        height: 80px;
        background: rgba(255, 255, 255, 0.6);
        opacity: 0.6;
        text-align: center;
        position: absolute;
        top: 36%;
        z-index: 800;
        cursor: pointer;

        @media (max-width: 1000px) {
            width: 5vw;
            height: 8.5vw;
            top: 12vw;
        }

        svg {
            margin-top: 20px;
            color: #fff;
            font-size: 45px;
            text-shadow: 0 0 5px #999;

            @media (max-width: 1000px) {
                margin-top: 2vw;
                font-size: 5vw;
            }
        }
    }

    .cycle-next {
        right: 0;
    }

    &.active {
        .cycle-prev,
        .cycle-next {
            display: block;
        }
    }
    // If JS hasn't loaded and no slide is selected, show the first slide
    &:not(:has(.slide.selected)) {
        .slide:nth-child(1 of .slide) {
            opacity: 1;
            z-index: 2;
            .slideshow-caption {
                opacity: 1;
            }
        }
    }
    .slide {
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s ease-in;
        user-select: none;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;

            @media (max-width: 600px) {
                height: 60vw;
            }
        }

        // Transition captions themselves to double their speed and avoid overlap
        .slideshow-caption,
        .text-wrapper > * {
            opacity: 0;
            transition: opacity 0.5s ease-in;
        }

        &.selected {
            opacity: 1;
            z-index: 2;
            transition: opacity 0.5s ease-out;
            .slideshow-caption,
            .text-wrapper > * {
                opacity: 1;
                transition: opacity 0.5s ease-out;
            }
        }

        .text-wrapper {
            padding: 12px 41px;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 5px;
            z-index: 9;

            @media (max-width: 600px) {
                padding: 1.5vw 3vw 2vw;
            }

            a {
                color: inherit;
                text-decoration: none;
            }

            .title {
                font-size: 2rem;
                line-height: 1.3;

                @media (max-width: 600px) {
                    font-size: 5vw;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .description {
                font-size: 0.95rem;
                line-height: 1.5;

                @media (max-width: 600px) {
                    font-size: 2.5vw;
                }
            }
        }
        .slideshow-caption {
            margin: 10px 0;
        }
    }
}

/* Ribbon banners */
.ribbon {
    padding: 2px 4px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 7px;
    font-size: 15px;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    display: block;
}

.ribbon-orange,
.ribbon-orange .banner,
.ribbon-orange span {
    background: #fbb450;
}
.ribbon-red,
.ribbon-red .banner,
.ribbon-red span {
    background: #863a3c;
    color: #fff;
}
.ribbon-yellow,
.ribbon-yellow .banner,
.ribbon-yellow span {
    background: #ffd95e;
}

.ribbon-red span,
.ribbon-orange span,
.ribbon-yellow span {
    padding: 1px 6px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px 0;
}

.ribbon-orange,
.ribbon-red,
.ribbon-yellow {
    background: none;
}

.ribbon .banner {
    width: 180px;
    height: 24px !important;
    padding: 10px 0 0 0;
    left: -39px;
    top: 33px;
    color: #fff;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-font-smoothing: antialiased;
    float: left;
    position: relative;
    display: block;
}
.ribbon .banner .text {
    min-height: auto;
    margin-right: 10px;
    font-size: 15px;
    text-align: center;
    line-height: 10px;
    @media #{$break400} {
        margin-left: 5px;
    }
}
.ribbon .banner:before {
    left: -76px;
}
.ribbon .banner .text:before,
.ribbon .banner .text:after {
    height: 30px;
}

.instanceImage {
    width: 100%;
    height: auto;
}

form#contact-form {
    .field-group {
        &.field {
            @media #{$break400} {
                width: 100%;
                margin-left: 0;
            }
        }
        &.city {
            width: 30%;
            margin-right: 20px;
        }
        &.state {
            width: 41%;
        }
        &.zipcode {
            width: 22%;
        }
    }
}
