.catalogWrapper {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
    .catalog {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-gap: 30px;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }
    }
    .sticky {
        position: sticky;
        top: 180px;
    }
    form {
        select {
            @media (max-width: 600px) {
                max-width: 82vw;
            }
        }
        img {
            margin-left: 10px;
            @media (max-width: 600px) {
                margin-left: 0;
                float: none !important;
            }
        }
    }
    .button.catalog-section-trigger {
        width: 200px;
        height: 25px;
        margin: 10px auto 6px 0;
        padding: 10px 20px;
        background: #9c7928;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        line-height: 1.4;
        outline: none;
        display: block;
    }
    .catalog-section-dropdown {
        max-height: 0;
        overflow-y: hidden;
        transition: max-height 0.5s ease-in-out;

        &.opened {
            max-height: 10000px;
        }

        .field input,
        .field textarea {
            max-width: 96%;
        }

        .travel-interests-field select {
            height: 150px;
        }
    }
}
.digDown {
    display: grid;
    grid-template-columns: 215px 1fr;
    grid-gap: 30px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
}
