.journeysFromhome {
    p {
        line-height: 1.6rem;
    }
    .twoPromos {
        margin-bottom: 0;
        h3 {
            color: $darkgold;
            a {
                color: $darkgold;
            }
        }
    }
    .landingTours {
        border-top: 5px solid #d1d1d1;
        margin-top: 40px;
        padding-top: 20px;
        .virtualTourList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 30px;
            h3 {
                color: $darkgold;
                a {
                    color: $darkgold;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .landingExperts {
        border-top: 5px solid #d1d1d1;
        margin-top: 40px;
        padding-top: 20px;
    }
    .landingContent {
        border-top: 5px solid #d1d1d1;
        padding-top: 20px;
        .photoGallerylist {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 30px;
            h3 {
                color: $darkgold;
                a {
                    color: $darkgold;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.meet-our-experts,
.morelink {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.85rem;
    a {
        color: $darkgold;
    }
}
