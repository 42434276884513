.specialOffersBodyColumns {
    .special-offers-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        margin-top: 20px;
        margin-bottom: 40px;
        h3 {
            a {
                color: $black;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
        .special-offer {
            color: $red;
        }
    }
}
