@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

// Media Queries
$break1500min: 'only screen and (min-width : 1500px)';
$break1400: 'only screen and (max-width : 1400px)';
$break1300: 'only screen and (max-width : 1300px)';
$break1200: 'only screen and (max-width : 1200px)';
$break1150: 'only screen and (max-width : 1150px)';
$break1100: 'only screen and (max-width : 1100px)';
$break1000: 'only screen and (max-width : 1000px)';
$break900: 'only screen and (max-width : 900px)';
$break800: 'only screen and (max-width : 800px)';
$break700: 'only screen and (max-width : 700px)';
$break600: 'only screen and (max-width : 600px)';
$break500: 'only screen and (max-width : 500px)';
$break400: 'only screen and (max-width : 400px)';
$break350: 'only screen and (max-width : 350px)';

// ===== VARIABLES =====
$white: #fff;
$black: #000;
$gold: #d9b859;
$darkgold: #9c7928;
$red: #c00;
$border: #e9e9e9;
$opensans: 'Open Sans', sans-serif;
$libreBaskerville: 'Libre Baskerville', serif;

// ===== MIXINS =====
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
@mixin box-shadow($box-shadow) {
    -moz-box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    -o-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}
@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: manual;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $libreBaskerville;
    font-weight: 400;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.6rem;
    font-weight: 700;
}
h3 {
    font-size: 1.5rem;
    color: #ab852d;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
    a {
        color: $gold;
    }
}

a {
    color: $black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

/* site header */
.cms-toolbar-expanded header {
    top: 46px !important;
    .navigation {
        top: 46px !important;
    }
}

.outofsight,
.visuallyhidden {
    display: none;
}

@import 'components/_header.scss';
@import 'pages/_homepage.scss';
@import 'pages/_catalog.scss';
@import 'pages/_landing.scss';
@import 'pages/_ship.scss';
@import 'pages/_flatpages.scss';
@import 'pages/_journeys-from-home.scss';
@import 'pages/_privacy.scss';
@import 'pages/_galleries.scss';
@import 'pages/_experts.scss';
@import 'pages/_blogging.scss';
@import 'pages/_reading-list.scss';
@import 'pages/_tour.scss';
@import 'pages/_tripfinder.scss';
@import 'pages/_tailor-made.scss';
@import 'pages/_student-adventures.scss';
@import 'pages/_special-offers.scss';
@import 'pages/_virtual.scss';
@import 'components/_newsletter.scss';
@import 'components/_widgets.scss';
@import 'components/_footer.scss';

// ===== GENERAL =====
p,
div,
ol,
li {
    font-family: $opensans;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

.siteWrapper {
    max-width: 960px;
    margin: 0 auto 50px;
    padding: 0 20px;
}

form#search select {
    background: #f1d69a;
    border: none;
    text-transform: uppercase;
}
.siteContainer {
    @media #{$break1000} {
        min-width: auto;
    }
}

.boldLineFullGray {
    @media #{$break1000} {
        display: none;
    }
}
.columns {
    @media #{$break800} {
        margin: 0;
    }
}
.outofsight {
    display: none !important;
}
.no-bullets {
    li {
        list-style-type: none;
    }
}
button,
.button {
    cursor: pointer;
}
.button.gold {
    margin-top: 50px;
    padding: 7px 0;
    background: #9c7928;
    text-transform: none;
    @media #{$break800} {
        margin-right: 1%;
    }
    @media #{$break600} {
        margin: -40px 15px 80px 15px;
    }
}
a.button.gold {
    color: $white !important;
    border-radius: 5px;
    background: #deb659; /* non-css3 browsers */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#deb659),
        to(#ad872e)
    ); /* webkit */
    background: -moz-linear-gradient(top, #deb659, #ad872e); /* firefox 3.6+ */
    padding: 5px 10px;
    margin-bottom: 20px;
}

.button.gold:hover {
    color: #fff;
    text-decoration: none;
    background: #b69b4e; /* non-css3 browsers */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#b69b4e),
        to(#7d672d)
    ); /* webkit */
    background: -moz-linear-gradient(top, #b69b4e, #7d672d); /* firefox 3.6+ */
}

#main.flatpage .travel-interests-field,
#main.tour-page .travel-interests-field {
    ul {
        margin: 10px 0 0px 15px;
        input[type='checkbox'] {
            margin-bottom: 10px !important;
            float: none !important;
            display: inline-block;
            vertical-align: top;
        }
    }
}

.collapse {
    overflow: hidden;
    transition: max-height 0.35s ease;
    position: relative;
}

// ===== CORONAVIRUS BANNER =====
#coronaStatement {
    padding: 10px 20px;
    background-color: yellow;
    text-align: center;
    font-weight: bold;
    position: relative;
    z-index: 9;

    a {
        text-decoration: underline;
    }

    i.fa-times {
        color: #333;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        top: 11px;
        left: 10px;
        display: block;
    }
}

// ===== SOCIAL CONNECT STICKY =====
.socialConnect {
    width: 62px;
    margin-left: 290px;
    text-align: center;
    position: fixed;
    top: 220px;
    @media #{$break1400} {
        top: 190px;
    }
    @media #{$break1150} {
        display: none !important;
    }
    img {
        margin: 0 auto;
    }
    p {
        //margin-bottom: 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-align: center;
        line-height: 17px;
    }
    a {
        margin-bottom: 10px;
        float: none;
    }
    a.catalog-newsletter {
        width: auto;
        height: auto;
        margin-left: 0;
        background: none;
    }
}

//===== HOMEPAGE =====
.home .siteWrapper {
    @media (max-width: 600px) {
        padding: 0;

        h2,
        p {
            padding: 0 20px;
        }

        .journeysFeatureditem {
            h2,
            h3,
            p,
            .description {
                padding: 0 20px;
            }
        }

        .journeysFeatureditem .description p {
            padding: 0;
        }
    }
}

//===== HOMEPAGE SIDEBAR =====
.homepage-sidebar,
#article-list-sticky-sidebar {
    width: 270px;
    margin: 51px 0 0 40px;
    display: inline-block;
    vertical-align: top;
    .catalog {
        margin-bottom: 20px;
        img {
            width: 120px;
            margin-right: 15px;
            display: inline-block;
            vertical-align: top;
        }
        .catalog-text {
            width: 130px;
            display: inline-block;
            vertical-align: top;
            h3 {
                padding: 0;
                font-size: 20px;
                text-align: left;
                text-transform: none;
            }
            p {
                margin-top: 5px;
                padding: 0 !important;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: lighter;
                line-height: 20px;
            }
            .button {
                width: 130px;
                height: 25px;
                padding-top: 4px;
                background: #9c7928;
                font-size: 16px;
                font-weight: normal;
                text-transform: none;
                line-height: 1.4;
            }
        }
    }
    .sidebar-newsletter {
        margin: 35px 0;
        a:hover {
            text-decoration: none;
        }
        h2 {
            margin-left: 10px;
            font-size: 22px !important;
            text-transform: none;
            display: inline;
        }
        p {
            color: #333;
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
            font-weight: lighter;
        }
        button {
            margin: 20px 0;
            padding: 4px 40px 5px 40px;
            background: #9c7928;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;
            float: right;
        }
    }
    .socialConnect {
        width: 62px;
        margin: -1165px 0 0 290px;
        margin-top: 0;
        text-align: center;
        position: fixed;
        img {
            margin: 0 auto;
        }
        p {
            //margin-bottom: 20px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            line-height: 17px;
        }
        a {
            margin-bottom: 10px;
            float: none;
        }
        a.catalog-newsletter {
            width: auto;
            height: auto;
            margin-left: 0;
            background: none;
        }
    }
    .socialConnectSticky {
        margin-top: 0 !important;
        position: fixed;
        top: 150px;
        z-index: 8;
    }
}
.homepage-sidebar {
    @media #{$break800} {
        display: none !important;
    }
}
.homepageSidebarSticky {
    margin-left: 40px;
    position: fixed;
    top: 180px;
    @media #{$break1400} {
        top: 150px;
    }
    @media #{$break1000} {
        margin-left: 63.5% !important;
    }
}
@-moz-document url-prefix() {
    .homepageSidebarSticky {
        margin-left: 40px !important;
        @media #{$break1000} {
            margin-left: 40px !important;
        }
    }
}

// ===== TOUR DETAIL PAGE =====
#main.booking-page {
    padding-top: 20px;
    @media #{$break1000} {
        padding: 60px 20px 0 20px;
    }
    .containerFixed {
        max-width: none;
        background: $white;
    }
    .tourHero {
        .four.columns.push-twelve {
            display: none;
        }
        .pull-four {
            width: 100%;
            right: inherit;
            margin: 0;
            .cycle-slideshow {
                margin-bottom: 5px;
                img {
                    width: 100%;
                }
            }
            iframe {
                width: 100%;
                position: relative;
                z-index: 9;
                @media #{$break800} {
                    height: 350px;
                }
                @media #{$break600} {
                    height: 250px;
                }
                @media #{$break400} {
                    height: 180px;
                }
            }
            .row.utilityBar {
                width: 320px;
                padding: 10px 0 4px 0;
                background: $white;
                float: right;
                @media #{$break1000} {
                    float: none;
                }
                .columns.twelve {
                    width: 200px;
                    @media #{$break400} {
                        width: 180px;
                    }
                }
                .share-top-links.columns {
                    float: none;
                    span {
                        width: 15px;
                        height: 32px;
                        margin: 0 0 0 15px;
                        padding-left: 17px;
                        background-size: cover;
                    }
                    .addthis_button_print.at300b span {
                        height: 100px;
                        margin-top: -46px;
                        margin-bottom: -25px;
                    }
                }
            }
            .credit {
                margin-top: 20px;
                font-family: $opensans;
                font-size: 16px;
                display: inline-block;
            }
        }
    }
    .boldLine {
        margin: 10px 0 20px 0;
        border-bottom: 4px solid #e6e6e6;
    }
    .tourTitle {
        width: 650px;
        padding: 0;
        font-family: $libreBaskerville;
        display: inline-block;
        @media #{$break1000} {
            width: 550px;
        }
        @media #{$break900} {
            width: 450px;
        }
        @media #{$break800} {
            width: 350px;
        }
        @media #{$break700} {
            width: 250px;
        }
        @media #{$break600} {
            width: auto;
            margin-bottom: 20px;
        }
        h1 {
            padding: 0;
            border: none;
            font-size: 42px;
            letter-spacing: -1px;
            @media #{$break800} {
                margin-bottom: -10px;
                font-size: 35px;
                line-height: 35px;
            }
        }
        h2 {
            margin: 5px 0;
            font-size: 25px;
        }
        h3 {
            margin: 10px 0;
            font-size: 18px;
            color: $gold;
            span {
                font-style: normal;
            }
        }
        h4 {
            margin: 30px 0 10px 0;
            color: $gold;
            font-size: 18px;
            font-weight: bold;
            display: inline-block;
            @media #{$break600} {
                margin: 20px 0 0 0;
                display: block;
            }
        }
        .button {
            width: 200px;
            margin: 15px 0 0 0;
            padding: 7px 15px 5px 15px;
            background: $gold;
            border: none;
            border-radius: 5px;
            color: $white;
            font-size: 20px;
            text-transform: none;
            a {
                color: $white;
            }
        }
        span {
            font-size: 18px;
            @media #{$break600} {
                display: none;
            }
        }
        p {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .tour-summary-box {
        width: 220px;
        padding: 20px 40px;
        background: #f0f0f0;
        font-family: $opensans;
        text-align: center;
        float: right;
        display: inline-block;
        @media #{$break600} {
            width: auto;
            padding: 20px 30px;
        }
        .bookTour {
            background: #f0f0f0;
        }
        h3 {
            margin-bottom: 8px;
            font-size: 22px;
            font-weight: bold;
        }
        h4 {
            margin-top: 8px;
        }
        select {
            width: 100%;
            height: 32px;
            margin: 4px 0;
            background: #858585;
            color: $white;
            font-size: 17px;
            option {
                text-transform: capitalize;
            }
        }
        button {
            width: 100%;
            height: 32px;
            margin: 4px 0;
            background: $darkgold;
            border: none;
            border-radius: 5px;
            color: $white;
            font-size: 16px;
        }
        button.black {
            background: $black;
        }
        h4 {
            color: #858585;
            font-size: 20px;
            font-weight: bold;
        }
    }
    #tourMenus.three.columns {
        display: none;
    }
    #tour-center-menu {
        padding-top: 30px;
        clear: both;
        ul {
            margin: 0;
            padding: 0;
            @media #{$break1000} {
                text-align: center;
            }
            a {
                color: #333;
                font-family: $libreBaskerville;
                font-size: 28px;
                display: inline-block;
                @media #{$break1000} {
                    width: 24.6%;
                    font-size: 24px;
                }
                @media #{$break800} {
                    width: 24.5%;
                    font-size: 19px;
                }
                @media #{$break600} {
                    width: 100%;
                    margin-bottom: 5px;
                    font-size: 25px;
                }
                &:hover {
                    text-decoration: none;
                }
                li {
                    width: 212px;
                    padding: 12px;
                    background: #d0d0d0;
                    text-align: center;
                    display: inline-block;
                    @media #{$break1000} {
                        width: 100%;
                        padding: 12px 0;
                    }
                    @media #{$break800} {
                        padding: 7px 0;
                    }
                }
                li.active {
                    background: $white;
                    border: solid 1px #333;
                    border-bottom: none;
                    color: $black;
                    font-weight: bold;
                    @media #{$break600} {
                        border: none;
                        box-shadow: 0 0 2px #000 inset;
                    }
                }
            }
            li.itineraries {
                width: 212px;
                margin-right: 24px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                @media #{$break1000} {
                    width: 185px;
                    margin-right: 0;
                }
                @media #{$break800} {
                    width: 135px;
                }
                @media #{$break600} {
                    width: 100%;
                }
                ul {
                    a li {
                        display: inline-block;
                    }
                    a.itinerary-date {
                        font-size: 17px;
                        display: none;
                        li {
                            width: auto;
                            margin-bottom: 0;
                            padding: 3px 0 0 10px;
                            border: none;
                            font-weight: bold;
                            text-align: left;
                            display: none;

                            margin-top: 5px;
                            padding: 5px 10px 3px 10px;
                            background: #d0d0d0;
                            border-radius: 8px;
                            color: $white;
                        }
                        li.active {
                            border: none;
                            background: $gold;
                        }
                    }
                }
            }
            li.itineraries.active {
                background: $white;
                border: solid 1px #333;
                border-bottom: none;
                color: $black;
                @media #{$break600} {
                    border: none;
                    box-shadow: 0 0 2px #000 inset;
                }
            }
        }
    }
    #tour-content {
        width: 100%;
        height: auto !important;
        margin: 0;
        padding: 20px 0 0 0;
        font-family: $libreBaskerville;
        @media #{$break600} {
            width: 100%;
        }
        a {
            color: $gold;
            word-wrap: break-word;
        }
        section {
            margin-bottom: 50px;
            padding-bottom: 30px;
            border-bottom: solid 5px #e6e6e6;
        }
        h2 {
            margin-top: 20px;
            font-size: 30px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            margin: 10px 0 15px 0;
        }
        p {
            margin-bottom: 12px;
            font-family: $opensans;
        }
        p,
        ul li {
            font-size: 17px;
        }
        ul {
            list-style-type: square;
        }
        iframe {
            @media #{$break600} {
                width: 100%;
            }
        }
        section.testimonials {
            .quote {
                margin: 30px 0;
                border-top: solid 1px #dcdcdc;
                display: none;
                &:first-of-type {
                    border: none;
                    display: block;
                }
                blockquote {
                    p {
                        color: #777;
                        font-size: 25px;
                        font-style: italic;
                        line-height: 35px;
                        &:before,
                        &:after {
                            content: '\201C';
                            margin: 10px 10px -10px 0;
                            color: #d0d0d0;
                            font-family: serif;
                            font-size: 70px;
                            float: left;
                            vertical-align: bottom;
                            vertical-align: -webkit-baseline-middle;
                            vertical-align: -moz-baseline-middle;
                        }
                        &:after {
                            content: '\201D';
                            float: none;
                        }
                    }
                }
            }
            .traveler {
                margin-top: -20px;
                font-size: 20px;
                float: right;
            }
            button {
                padding: 10px 20px;
                background: $gold;
                border: none;
                color: $white;
                font-size: 15px;
                font-weight: bold;
            }
            .row.ultrathinLineFull {
                display: none;
            }
        }
        p.meal-key {
            display: block;
        }
        .itinerary-option {
            margin-bottom: 10px;
            padding: 6px 20px;
            background: #d0d0d0;
            color: #333;
            font-size: 20px;
            font-weight: bold;
            display: none;
            &:hover {
                cursor: pointer;
            }
            .fa {
                margin: -3px 20px 0 0;
                font-size: 25px;
                display: inline-block;
                vertical-align: top;
            }
            .fa-sort-asc {
                margin: 9px 20px -10px 0;
            }
        }
        .itinerary {
            .tour-contents-list {
                margin-bottom: 35px;
                .tourDetailsSpecialOffer {
                    border-bottom: none;
                }
            }
            button.terms-conditions {
                margin: 0 0 20px 0;
                padding: 10px 15px;
                background: $gold;
                border: none;
                color: $white;
                font-size: 18px;
            }
        }
        .itinerary.show {
            display: block !important;
        }
        #GTM-journeys-esri-map {
            width: 400px;
            margin: 0 0 10px 20px;
            float: right;
            position: relative;
            @media #{$break800} {
                width: 300px;
            }
            @media #{$break600} {
                width: 100%;
                margin: 0;
                float: none;
            }
            button.fullscreen,
            button.print {
                padding: 5px 7px;
                background: $black;
                border: none;
                border-radius: 3px;
                color: $white;
                font-size: 14px;
                font-weight: bold;
                position: absolute;
                top: 13px;
                right: 9px;
                @media #{$break1000} {
                    display: none !important;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            button.print {
                width: 80px;
                margin-top: 3.5%;
                left: 37%;
                display: none;
            }
            button.fullscreen.topleft {
                left: 9px;
                right: auto;
            }
            button.fullscreen.bottomleft {
                top: auto;
                left: 9px;
                right: auto;
                bottom: 13px;
            }
            button.fullscreen.bottomright {
                top: auto;
                bottom: 13px;
            }
            .fa-times {
                padding: 10px 15px;
                border: solid 5px #dcdcdc;
                border-radius: 100%;
                box-shadow: 0 0 10px #333;
                color: #dcdcdc;
                font-size: 40px;
                text-shadow: 0 0 10px #000;
                position: fixed;
                top: 140px;
                right: 11.3%;
                display: none;
                @media #{$break1200} {
                    top: 130px;
                    right: 10.5%;
                }
                @media #{$break1500min} {
                    top: 160px;
                    right: 19.5%;
                }
                &:hover {
                    color: $white;
                    border: solid 5px $white;
                    cursor: pointer;
                }
            }
            iframe {
                width: 100%;
                position: relative;
                z-index: 9;
            }
        }
        // For full screen map
        .fullScreen {
            width: 100% !important;
            margin: 0 !important;
            padding: 5% 15%;
            background: rgba(0, 0, 0, 0.8);
            position: fixed !important;
            top: 120px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 998;
            @media #{$break1500min} {
                width: 80% !important;
                padding: 5% 22%;
            }
        }

        .itinerary-hotel,
        .itinerary-transportation {
            div {
                color: $gold;
                font-size: 17px;
                font-weight: bold;
                .fa {
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
        }
        .tourDetailsSpecialOffer {
            margin: 0;
            padding-bottom: 10px;
            background: none;
            h4,
            p {
                padding: 0;
                color: #d42930;
                font-size: 17px;
                font-weight: normal;
                line-height: 25px;
            }
            h4 {
                margin-bottom: 5px;
                font-weight: bold;
            }
        }
        .tour-pricing {
            h1 {
                font-size: 38px;
            }
            p {
                margin-bottom: 20px;
                font-size: 19px;
            }
            .priceDeparture {
                &:last-of-type {
                    margin-bottom: 30px;
                }
                h3 {
                    padding: 10px 25px 10px 15px;
                    background: #d0d0d0;
                    color: #333;
                    @media #{$break600} {
                        padding-right: 10px;
                    }
                    .fa-sort-desc,
                    .fa-sort-asc {
                        margin: -7px 20px 0 0;
                        font-size: 25px;
                        display: inline-block;
                        vertical-align: top;
                        @media #{$break600} {
                            margin-right: 10px;
                        }
                        @media #{$break400} {
                            margin: -7px 5px 0 -5px;
                        }
                    }
                    .fa-sort-asc {
                        margin: 4px 20px -10px 0;
                    }
                    .date,
                    .departure-status {
                        font-size: 18px;
                        display: inline-block;
                        vertical-align: middle;
                        @media #{$break600} {
                            margin-top: 3px;
                            font-size: 12px;
                        }
                        @media #{$break400} {
                            font-size: 10px;
                        }
                    }
                    .departure-status {
                        font-style: italic;
                    }
                }
                table.tourcost {
                    @media #{$break500} {
                        width: 100%;
                        table-layout: fixed;
                        font-size: 10px;
                    }
                    .button {
                        color: $white;
                    }
                    .button-ask-question {
                        color: $white;
                    }
                }
            }
        }
        .boldLine {
            margin: 40px 0;
        }
        // Experts tab
        .tour-page-experts {
            height: auto !important;
            @media #{$break600} {
                text-align: center;
            }
            .dates {
                margin: 10px 0;
                padding-top: 30px;
                border-top: solid 5px #e6e6e6;
                color: $gold;
                font-size: 22px;
                font-weight: bold;
                &:first-of-type {
                    padding-top: 0;
                    border: none;
                }
            }
            img {
                width: 200px;
                margin-top: 20px;
                vertical-align: top;
                @media #{$break600} {
                    margin: 0 auto;
                    float: none;
                }
            }
            .expert {
                width: 700px;
                margin: 10px 0 50px 15px;
                display: inline-block;
                @media #{$break1000} {
                    width: auto;
                }
                h2 {
                    font-size: 30px;
                    a {
                        color: $black;
                    }
                }
                p {
                    font-size: 19px;
                }
            }
        }

        // Tour details tab
        .tour-page-details {
            iframe {
                @media #{$break800} {
                    width: 80%;
                    padding: 0 10%;
                }
            }

            section.reading-list {
                p {
                    color: $gold;

                    a {
                        font-weight: bold;
                    }
                }
            }

            section.reading-list {
                p {
                    color: $gold;

                    a {
                        font-weight: bold;
                    }
                }
            }

            section.transportation {
                img {
                    @media #{$break800} {
                        width: 100%;
                    }
                }

                .cycle-slideshow {
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .cycle-prev,
                    .cycle-next {
                        @media #{$break500} {
                            top: 28%;
                        }
                        @media #{$break400} {
                            top: 35%;
                        }
                    }
                }
            }

            section.insurance {
                display: none;
            }

            section.testimonials {
                .row.quote {
                    margin-bottom: 30px;
                    padding-top: 30px;
                    display: block;
                    &:first-of-type {
                        padding-top: 0;
                    }
                }

                blockquote {
                    p {
                        color: #777;
                        font-size: 25px;
                        font-style: italic;
                        line-height: 35px;
                        &:before,
                        &:after {
                            content: '\201C';
                            margin: 10px 10px -10px 0;
                            color: #d0d0d0;
                            font-family: serif;
                            font-size: 70px;
                            float: left;
                            vertical-align: bottom;
                            vertical-align: -webkit-baseline-middle;
                            vertical-align: -moz-baseline-middle;
                        }
                        &:after {
                            content: '\201D';
                            float: none;
                        }
                    }
                }
                .traveler {
                    margin-top: -30px;
                    font-size: 20px;
                    float: right;
                }
                .row.ultrathinLineFull {
                    display: none;
                }
            }
            section {
                padding-bottom: 0;
                border-bottom: none;
            }
            .section-title {
                margin-bottom: 10px;
                padding: 6px 20px;
                background: #d0d0d0;
                color: #333;
                font-size: 20px;
                font-weight: bold;
                &:hover {
                    cursor: pointer;
                }
                .fa {
                    margin: -3px 20px 0 0;
                    font-size: 25px;
                    display: inline-block;
                    vertical-align: top;
                }
                .fa-sort-asc {
                    margin: 9px 20px -10px 0;
                }
            }
            .detail-section {
                padding-top: 20px;
                display: none;
                h3 {
                    margin-bottom: -10px;
                }
                h4 {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: solid 1px #dcdcdc;
                    font-size: 20px;
                    &:first-of-type {
                        margin-top: 0;
                        padding-top: 0;
                        border: none;
                    }
                }
                .tour-accommodations .cycle-slideshow {
                    max-width: 645px;
                }
            }
            .detail-section.show {
                display: block !important;
            }
        }
    }
    .related-tours {
        font-family: $libreBaskerville;
        clear: both;
        @media #{$break1000} {
            text-align: center;
        }
        h1 {
            margin: 10px 0;
            font-size: 35px;
        }
        h2 {
            margin-bottom: 20px;
            font-size: 30px;
        }
        .related-tour {
            width: 280px;
            margin: 10px 0 20px 45px;
            display: inline-block;
            vertical-align: top;
            @media #{$break1000} {
                margin: 10px auto 20px auto;
                margin-left: 10px;
                margin-right: 10px;
            }
            @media #{$break400} {
                margin-left: 0;
                margin-right: 0;
            }
            &:nth-of-type(3n + 1) {
                margin-left: 0;
                @media #{$break1000} {
                    margin-left: 10px;
                }
                @media #{$break400} {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            img {
                width: 100%;
            }
        }
        .boldLine {
            margin: 0;
        }
    }
    .homepage-sidebar {
        .catalog,
        .sidebar-newsletter {
            display: none;
        }
        .socialConnect {
            margin-left: 950px;
            p {
                color: #000;
            }
        }
    }
}
// Firefox only
@-moz-document url-prefix() {
    #main.tour-page
        .tourHero
        .pull-four
        .row.utilityBar
        .share-top-links.columns
        .addthis_button_print.at300b
        span {
        width: 20px;
        height: 200px;
        margin-top: -170px;
    }
}

// ===== TOUR CONTACT FORM ======
#tour-contact-form {
    .field {
        @media #{$break600} {
            width: 100%;
        }
        ul {
            list-style-type: none;
        }
        input {
            box-shadow: none;
            vertical-align: middle;
        }
    }
    .field.last {
        @media #{$break600} {
            margin-left: 0;
        }
    }
    #id_last_name {
        width: 99% !important;
        @media #{$break600} {
            width: 97% !important;
        }
    }
    #id_street {
        width: 97.5%;
    }
    .zipcode {
        width: 23%;
    }
}
#tour-contact-form,
#catalog-form {
    input[type='radio'] {
        margin: 0 3px 3px 0;
    }
}
#main.flatpage,
#main.tour-page {
    h2.form-dropdown {
        margin: 5px 0 30px;
        padding: 5px 15px;
        background: #d0d0d0;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        &:hover {
            cursor: pointer;
        }
        .fa-sort-desc,
        .svg-inline--fa {
            margin: 0px 10px 7px 2px;
            vertical-align: top;
        }
        .fa-sort-asc {
            margin: 7px 10px 0px 2px;
        }
    }
    #tour-brochure-form {
        h2.form-dropdown {
            margin: 5px 0 30px;
            font-size: 20px;
        }
    }
}
.dropdown-section {
    max-height: 0;
    overflow: hidden;
    select {
        width: 100%;
        min-height: 150px;
        @media #{$break800} {
            min-height: 0;
        }
    }
}
div.required {
    margin-top: 20px;
}

.required-note {
    margin-bottom: 30px;
    font-size: 16px;

    .required {
        font-weight: bold;
    }
}

// ===== TOUR BROCHURE PAGE =====
#tour-brochure-form .field ul li {
    list-style-type: none;
    label input {
        margin-top: 5px;
    }
}

// ===== BOOKING PAGE =====
#main.booking-page {
    .row .eight {
        @media #{$break800} {
            width: 100%;
        }
    }
}

// ===== TRANSPORTATION DETAIL PAGE =====
#main.transportation-detail-page {
    h1 {
        font-size: 30px;
        font-weight: bold;
    }
    p {
        clear: none !important;
    }
    .row.singleExpert,
    .row.ourExperts {
        @media #{$break800} {
            text-align: center;
        }
        .five.columns {
            margin-right: 20px;
            float: left;
            @media #{$break800} {
                width: auto;
                margin-bottom: 20px;
                float: none;
            }
        }
        .eleven.columns {
            width: auto;
            float: none;
            @media #{$break800} {
                width: auto;
                float: none;
            }
            img {
                width: 100%;
            }
            p {
                margin-bottom: 20px;
                text-align: left;
            }
        }
        .cycle-slideshow {
            @media #{$break400} {
                width: 95%;
                margin: 0 10px;
            }
        }
    }
}

// ===== EXPERTS PAGE =====
#main.experts-page {
    padding-top: 220px;
    background: $white;
    @media #{$break1000} {
        padding: 50px 20px 0 20px;
    }
    .row {
        .twelve {
            width: auto;
        }
    }
    .row.featuredExperts {
        border-top: solid 5px #dcdcdc;
        font-family: $opensans;
        @media #{$break600} {
            text-align: center;
        }
        h1 {
            margin: 32px 0;
            font-family: $libreBaskerville;
            font-size: 40px;
            text-align: center;
            .gold {
                color: $gold;
            }
        }
        img {
            width: 140px;
            margin-bottom: 30px;
            @media #{$break600} {
                margin-bottom: 0;
            }
        }
        .expertsName {
            margin-top: -2px;
            a {
                color: $gold;
                font-size: 21px;
                font-weight: lighter;
            }
        }
        p.text12 {
            font-size: 18px;
            @media #{$break1000} {
                font-size: 16px;
            }
        }
        .eleven {
            @media #{$break800} {
                width: 70%;
            }
            @media #{$break600} {
                width: 80%;
                margin: 0 auto;
                float: none;
            }
            @media #{$break400} {
                width: auto;
            }
        }
        .eight {
            @media #{$break800} {
                width: auto;
            }
        }
        .five {
            @media #{$break800} {
                width: auto;
                margin-right: 10px;
            }
            @media #{$break600} {
                margin: 40px auto 0 auto;
                float: none;
            }
        }
    }
    .ourExperts {
        h1 {
            margin-bottom: 15px;
            font-size: 39px;
            .gold {
                color: $gold;
            }
        }
        p {
            color: #000;
            font-family: $opensans;
            font-size: 17px;
            line-height: 28px;
        }
    }
    .searchExperts {
        .text14 {
            width: auto;
            strong {
                font-family: $opensans;
                font-size: 19px;
                vertical-align: middle;
            }
        }
        form#expertsearch {
            margin-bottom: 20px;
            @media #{$break600} {
                margin-bottom: 0;
            }
            legend {
                @media #{$break1000} {
                    float: none;
                }
            }
            select {
                height: auto;
                margin: 6px 20px 0 10px;
                background: #ededed;
                border: none;
                font-size: 18px;
                text-transform: none;
                @media #{$break1000} {
                    margin: 6px auto 0 auto;
                    float: none;
                }
            }
            input {
                width: 300px;
                max-width: none;
                margin-top: 5px;
                padding: 4px 5px 2px 5px;
                border: solid 1px #dcdcdc;
                box-shadow: none;
                @media #{$break1000} {
                    width: 200px;
                    margin: 10px 0;
                    float: none;
                    display: block;
                }
            }
            button {
                margin: 4px 0 0 20px;
                padding: 2px 20px;
                background: $gold;
                border-radius: 3px;
                font-family: $opensans;
                font-size: 14px;
                text-transform: none;
                line-height: 19px;
                float: none;
                display: inline-block;
                @media #{$break1000} {
                    margin: 0 auto;
                }
            }
        }
    }
    .row.paginationSection {
        .columns.four {
            @media #{$break600} {
                width: auto;
                margin: 0 auto;
                text-align: center;
                float: none;
            }
        }
    }
    .homepage-sidebar {
        .catalog,
        .sidebar-newsletter {
            display: none;
        }
    }
    .socialConnect {
        margin-left: 940px;
        p {
            color: $black;
        }
    }
}

// ===== GALLERY PAGES =====
#main.gallery-page,
#main.gallery-detail-page {
    padding-top: 220px;
    @media #{$break1000} {
        padding: 70px 20px;
    }
    @media #{$break400} {
        padding: 70px 10px;
    }
    h1 {
        margin-bottom: 10px;
        font-size: 40px;
        float: none;
    }
    h2 {
        margin: 25px 0 20px 0;
        color: #555;
        font-size: 30px;
        text-align: left;
        @media #{$break1000} {
            font-size: 35px;
            text-align: center;
        }
    }
    h3 {
        margin-top: 20px;
        @media #{$break1000} {
            margin-top: 5px;
        }
        a {
            color: $black;
            font-family: $libreBaskerville;
            font-size: 30px;
        }
    }
    .galleries {
        margin-bottom: 0;
        @media #{$break1000} {
            text-align: center;
        }
        .row {
            margin-bottom: 0;
        }
        .row .photoChannelHero {
            width: auto;
        }
        .row .oneOfThree.columns {
            width: 285px;
            margin-left: 33px;
            margin-bottom: 40px;
            @media #{$break1000} {
                margin: 0px 10px 25px 10px !important;
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            &:nth-of-type(3n + 1) {
                margin-left: 0;
            }
            img {
                width: 100%;
            }
        }
    }
    .paginationSection {
        font-family: $opensans;
        @media #{$break1000} {
            width: auto;
            padding: 0 20px;
        }
        @media #{$break400} {
            padding: 0;
        }
        .columns.four,
        .columns.twelve {
            width: auto;
            float: none;
            display: inline-block;
            vertical-align: middle;
        }
        .columns.twelve {
            float: right;
        }
    }
    .galleries-sidebar {
        .catalog,
        .sidebar-newsletter {
            display: none;
        }
        .socialConnect {
            margin-left: 930px;
            p {
                color: $black;
            }
        }
    }
}
#main.gallery-detail-page {
    .back-to-galleries a {
        padding-left: 10px;
        color: $gold;
        font-family: $opensans;
        font-size: 20px;
        font-weight: bold;
    }
    h1 {
        margin: 10px 0 20px 10px;
    }
    h2 {
        color: $black;
        font-size: 40px;
    }
    .slideshowContainer {
        .cycle-slideshow {
            margin-bottom: 10px;
            @media #{$break1000} {
                margin-bottom: 0;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .credit {
            width: 55%;
            margin: 10px 0;
            font-family: $opensans;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
            @media #{$break1000} {
                width: auto;
            }
        }
        .utilityBar {
            width: auto;
            float: right;
            @media #{$break1000} {
                float: none;
            }
            .socialBar {
                width: 310px;
                position: relative;
                z-index: 999;
                .columns.twelve,
                .share-top-links {
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                }
                .share-top-links {
                    margin-left: 45px;
                    color: $white;
                    span {
                        background-size: cover;
                    }
                    .addthis_button_print span {
                        margin-left: -10px;
                        background-position-y: -100px;
                    }
                }
            }
        }
    }
    .columns.twelve {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        .galleries {
            width: 620px;
            display: inline-block;
            @media #{$break1000} {
                width: auto;
            }
            .row .oneOfThree.columns {
                margin: 0 50px 50px 0;
                clear: none;
                &:nth-of-type(even) {
                    margin-right: 0;
                }
            }
        }
    }
    .homepage-sidebar {
        width: 270px;
        margin-left: 40px !important;
        float: none;
        @media #{$break1000} {
            display: none !important;
        }
        h2 {
            margin-left: 10px;
            font-size: 22px;
        }
        .socialConnect {
            margin: 10px 0 0 320px;
        }
    }
}

// ===== FLATPAGE and LANDING PAGE =====
#main.flatpage,
#main.landing-page,
#main.toursbyyear-page {
    min-height: 700px;
    padding-top: 210px;
    padding-bottom: 30px;
    background: #fff;
    font-family: $libreBaskerville;
    @media #{$break1000} {
        padding-top: 40px;
    }
    @media #{$break800} {
        min-height: 0;
        padding-top: 55px;
    }
    @media #{$break400} {
        padding-top: 40px;
    }
    h1 {
        margin: 10px 0;
    }
    h1.text25 {
        @media #{$break400} {
            font-size: 34px;
        }
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        margin-top: 15px;
        @media #{$break400} {
            padding: 0 10px;
        }
    }
    h1,
    h2,
    h3,
    p,
    ol,
    form,
    .credit {
        @media #{$break400} {
            padding: 0 10px;
        }
    }
    p {
        strong {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .cycle-slideshow {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        float: left;
        @media #{$break800} {
            width: auto;
        }
        @media #{$break400} {
            width: 100%;
            margin-bottom: 8px;
        }
        img {
            @media #{$break600} {
                width: 100%;
            }
        }
        .slide {
            a {
                width: 100%;
                img {
                    @media #{$break1000} {
                        height: 450px;
                    }
                    @media #{$break800} {
                        height: 400px;
                    }
                    @media #{$break600} {
                        height: 300px;
                    }
                    @media #{$break600} {
                        height: 250px;
                    }
                }
            }
        }
        .slide-sidebar {
            @media #{$break600} {
                //width: 92%;
                padding: 8px 20px;
            }
            h2 {
                @media #{$break600} {
                    font-size: 25px;
                }
            }
        }
        @media #{$break400} {
            top: 28%;
        }
    }
    .credit {
        font-size: 18px;
    }
    .belowContent {
        font-size: 20px;
        div {
            strong {
                font-size: 30px;
                line-height: 37px;
            }
        }
    }
    .article-image img {
        width: 100%;
    }
    .containerFixed .twelve.columns {
        width: auto;
        @media #{$break1000} {
            width: auto;
            margin: 0 10px;
        }
        @media #{$break800} {
            margin-left: 2.5%;
            float: none;
        }
        @media #{$break400} {
            margin: 0;
        }
        .articleBody {
            a {
                word-wrap: break-word;
            }
        }
    }
    .text25 {
        margin-bottom: 15px;
        font-size: 38px;
    }
    p {
        font-size: 17px;
        clear: both;
    }
    .row .columns .row {
        .oneOfThree.columns {
            width: 30%;
            margin-left: 4%;
            margin-bottom: 40px;
        }
        .columns.oneOfThree:nth-child(3n + 1) {
            margin-left: 0;
        }
        .twoOfThree.columns {
            @media #{$break400} {
                width: auto;
            }
        }
    }
    .oneOfThree a img {
        width: 100%;
    }
    .tourTypesGroup {
        h3 {
            margin-bottom: 12px;
            padding-bottom: 10px;
            border-bottom: solid 4px #dcdcdc;
            font-family: $libreBaskerville;
            font-size: 28px;
            font-weight: normal;
        }
    }
    .row.searchResults {
        @media #{$break600} {
            margin-bottom: 80px;
        }
        .oneOfThree.columns {
            @media #{$break600} {
                margin-bottom: -15px !important;
            }
        }
    }
    .socialConnect,
    .socialConnectSticky {
        width: 62px;
        margin-left: 960px;
        text-align: center;
        position: fixed;
        top: 210px;
    }
    @-moz-document url-prefix() {
        .socialConnect {
            margin-left: 980px;
        }
    }
    .socialConnect {
        top: 150px;
        .catalog-newsletter img {
            width: 100%;
        }
        .newsletter-sidebar img {
            width: 42px;
        }
        p {
            margin-bottom: 20px;
            font-size: 14px;
            text-align: center;
            line-height: 17px;
        }
        a.catalog-newsletter {
            width: auto;
            height: auto;
            margin-left: 0;
            margin-bottom: -10px;
            background: none;
        }
        a {
            margin-bottom: 10px;
            float: none;
        }
    }
    .catalog,
    .sidebar-newsletter {
        display: none;
    }
    .homepage-ads-bottom {
        margin-left: 1%;
        margin-bottom: 30px;
        .middle-ad {
            margin-bottom: 50px;
        }
    }
    .boldLineFullGray {
        display: none;
    }
}

// ===== FLATPAGE =====
#main.flatpage {
    ul {
        margin: 20px 20px 20px 30px;
        font-size: 15px;
        list-style-type: square;
    }
    .containerFixed .twelve.columns {
        width: 650px;
        @media #{$break1000} {
            width: auto;
            margin: 0 10px;
        }
        @media #{$break800} {
            margin-left: 2.5%;
            float: none;
        }
        @media #{$break400} {
            margin: 0;
        }
    }
    .cycle-slideshow {
        width: 100%;
        margin-right: 0;
        @media #{$break800} {
            width: 98%;
        }
        img {
            height: 470px;
            @media #{$break1000} {
                width: 100%;
                height: auto;
            }
        }
    }
    .row {
        .homepage-sidebar {
            width: 270px;
            margin: 70px 0 0 40px;
            position: fixed;
            top: 155px;
            @media #{$break1400} {
                top: 125px;
            }
            @media #{$break1100} {
                margin-left: 20px;
            }
        }
        .columns .row .oneOfThree.columns {
            @media #{$break600} {
                width: 47%;
                margin-left: 0;
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            @media #{$break400} {
                width: 100%;
                margin-left: 0;
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            &:nth-of-type(even) {
                @media #{$break600} {
                    margin-left: 4%;
                }
                @media #{$break400} {
                    margin-left: 0;
                }
            }
            div h3,
            div p {
                @media #{$break400} {
                    padding: 0 12px 10px 12px;
                }
            }
        }
    }
    .oneOfThree a img {
        @media #{$break400} {
            margin-bottom: 10px;
        }
    }
    .row.hero {
        @media #{$break400} {
            width: auto;
        }
    }
    .belowContent {
        @media #{$break1000} {
            margin-left: 0;
        }
        @media #{$break400} {
            width: auto;
            padding: 0 10px;
        }
    }
    .catalog,
    .sidebar-newsletter {
        display: block;
    }
    .socialConnect {
        margin-left: 290px;
        top: 220px;
        @media #{$break1400} {
            top: 190px;
        }
        .catalog-newsletter {
            display: none;
        }
    }
    .ribbon .banner .text {
        @media #{$break400} {
            margin-left: 5px;
        }
    }
}

// ===== ARCHIVE PAGE =====
#main.toursbyyear-page {
    .goldText.text18.futuraStdBook {
        display: block;
    }
}

// ===== SPECIAL OFFERS PAGE ======
#main.flatpage .row.special-offers-wrapper .oneOfThree a img {
    margin-bottom: 0;
}

// ===== LANDING PAGE =====
#main.landing-page {
    .row.hero {
        @media #{$break800} {
            width: auto;
            padding: 0 10px;
        }
        @media #{$break400} {
            padding: 0;
        }
        .cycle-slideshow {
            @media #{$break800} {
                margin-right: 0;
            }
            p {
                @media #{$break400} {
                    padding: 0;
                }
            }
        }
    }
    .goldText.text18.futuraStdBook {
        display: none;
    }
    .row .columns .tourTypesGroup .row {
        @media #{$break600} {
            padding: 10px;
        }
        @media #{$break400} {
            padding: 0;
        }
        .oneOfThree.columns {
            @media #{$break600} {
                width: 45%;
                margin-left: 0;
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            @media #{$break400} {
                width: auto;
            }
            &:nth-of-type(3n + 1) {
                @media #{$break600} {
                    margin-left: 0;
                }
            }
            &:nth-of-type(even) {
                @media #{$break600} {
                    margin-left: 4%;
                }
                @media #{$break400} {
                    margin-left: 0;
                }
            }
            div {
                @media #{$break400} {
                    padding: 0 10px;
                }
            }
            a img {
                margin-bottom: 0;
            }
        }
    }
    .socialConnect {
        top: 220px;
        @media #{$break1400} {
            top: 190px;
        }
    }
}

//===== CATALOG PAGE =====
#main.catalog-page {
    form.webtolead {
        input {
            border: solid 1px #999;
            box-shadow: none;
        }
        button {
            background: $gold;
        }
        .field-group {
            &.field {
                @media #{$break400} {
                    width: 100%;
                    margin-left: 0;
                }
            }
            &.city {
                width: 30%;
                margin-right: 20px;
            }
            &.state {
                width: 41%;
            }
            &.zipcode {
                width: 22%;
            }
        }
    }
    .homepage-sidebar {
        @media #{$break1000} {
            display: none;
        }
        .catalog {
            display: none;
        }
        .socialConnect {
            margin-top: 50px;
            margin-left: 270px;
            display: block !important;
            @media #{$break1200} {
                display: none !important;
            }
            a.catalog-newsletter:first-of-type {
                display: none;
            }
        }
    }
    .digDown {
        @media #{$break800} {
            text-align: center;
        }
        div {
            @media #{$break800} {
                width: auto !important;
                margin: 0 auto;
                float: none !important;
            }
        }
    }
    .thank-you {
        margin-right: 100px;
        @media #{$break1000} {
            margin-right: 0;
        }
    }
}

//===== CATALOG DOWNLOAD PAGE =====
#main.catalog-download {
    padding-top: 150px;
    .homepage-sidebar {
        width: 270px;
        margin: 70px 0 0 40px;
        position: fixed;
        top: 85px;
        .catalog-newsletter.newsletter-sidebar {
            display: none;
        }
    }
}

//===== OUR SHIPS PAGE =====
#main.our-ships {
    .featuredExperts {
        .eight.columns {
            margin-bottom: 20px;
            @media #{$break600} {
                width: auto;
            }
            @media #{$break400} {
                padding: 0 10px;
            }
        }
        img {
            @media #{$break600} {
                width: 100%;
            }
        }
    }
    .row .homepage-sidebar {
        @media #{$break1000} {
            display: none;
        }
    }
}

//===== NEWSLETTER SIGNUP =====
#main.newsletter-page {
    .newsletter-pref-center .field-group .field {
        @media #{$break400} {
            width: 100%;
        }
        input {
            @media #{$break400} {
                width: 100%;
            }
        }
    }
    .newsletter-pref-center .field-group .field:last-child,
    div.laster {
        @media #{$break400} {
            margin-left: 0;
        }
    }
    .sidebar-newsletter {
        display: none;
    }
}

//===== SEARCH RESULTS PAGE =====
#main.search-results {
    h2,
    .text16 {
        @media #{$break400} {
            padding: 0 10px;
        }
    }
    h2 {
        @media #{$break400} {
            margin-top: 10px;
        }
    }
    .containerFixed {
        .row {
            .searchResults {
                margin: 0 auto 20px;
                display: grid;
                grid-template-columns: 190px 1fr;
                grid-gap: 30px;
                border-bottom: 2px solid #e1e1e1;
                @media (max-width: 600px) {
                    margin-bottom: 50px;
                    display: block;
                }
                &.tailor-made-promo {
                    .tourImage {
                        img {
                            margin-bottom: 0;
                        }
                    }
                    .tourDetail {
                        h2 {
                            display: inline-block;
                        }
                        p {
                            padding-right: 9px;
                            padding-left: 20px;
                        }
                        a.learn-more {
                            margin: 10px 0 0;
                            padding: 4px 8px;
                            border: solid 2px #d9b859;
                            color: #333;
                            font-weight: lighter;
                        }
                    }
                }
                .tourImage {
                    img {
                        width: 100%;
                        height: auto;
                        display: inline-block;
                        margin-bottom: 15px;
                    }
                }
                .tourDetail {
                    h2 {
                        margin-top: 0;
                        padding-top: 0;
                        display: block;
                        font-weight: normal;
                        font-size: 1.7rem;
                    }
                    p {
                        text-align: center;
                        font-size: 0.9rem;
                        font-weight: 700;
                        float: right;
                        margin-top: 0;
                        display: inline-block;
                        .gold-price {
                            color: #9c7928;
                            font-size: 30px;
                            font-weight: 700;
                        }
                    }
                    ul {
                        margin: 10px 20px 20px 30px;
                        padding: 0;
                        list-style-type: square;
                        line-height: 1.4rem;
                    }
                    div {
                        margin: 0;
                        padding: 0;
                        font-size: 0.8rem;
                    }
                    .see-more {
                        width: 74px;
                        margin: 10px 0 0 -18px;
                        padding: 1px 5px;
                        border: solid 2px #d9b859;
                        color: #444;
                        font-size: 12px;
                        text-align: center;
                        display: none;
                        cursor: pointer;
                        .fa-plus {
                            margin-right: 5px;
                            color: $gold;
                            font-size: 12px;
                        }
                        &:hover,
                        &:hover > .fa-plus {
                            background: $gold;
                            color: $white;
                        }
                    }
                }
            }
            .row.tailor-made-promo {
                padding: 15px 0;
                background-color: #f2f2f2;
                h2,
                p {
                    @media #{$break1400} {
                        padding-left: 15px;
                    }
                }
            }
            .columns .row .oneOfThree.columns,
            .columns .row .twoOfThree.columns {
                width: 310px;
                margin: 0;
                float: none;
                display: inline-block;
                vertical-align: top;
                @media #{$break900} {
                    width: 250px;
                }
                @media #{$break800} {
                    width: 55%;
                }
                @media #{$break600} {
                    width: auto;
                }
            }
            .columns .row .oneOfThree.columns {
                margin-right: 20px;
                @media #{$break1000} {
                    width: auto;
                }
                @media #{$break800} {
                    width: auto;
                    margin-left: 0;
                    margin-right: 20px;
                }
                @media #{$break600} {
                    width: 99% !important;
                }
                @media #{$break400} {
                    width: 100% !important;
                }
            }
            .homepage-sidebar {
                @media #{$break900} {
                    right: 10px;
                }
            }
        }
    }
    .searchResults .oneOfThree {
        img {
            margin-bottom: 5px;
            @media #{$break1000} {
                width: 220px;
                max-width: none;
                height: 130px;
            }
            @media #{$break600} {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
            }
        }
    }
    .columns h2.bold {
        font-weight: normal;
    }
    .search-audley .goldText {
        color: #ab852d;
    }
    .socialConnect {
        margin-left: 270px;
        text-align: center;
        position: fixed;
        top: 210px;
        @media #{$break1000} {
            margin-left: 83%;
        }
        @media #{$break600} {
            display: none;
        }
        p {
            width: 100px;
            margin-bottom: 20px;
        }
        .text12 {
        }
    }
    .search-audley {
        @media #{$break900} {
            width: 90%;
        }
        @media #{$break800} {
            width: auto;
        }
    }
}

// ===== ARTICLE LIST AND DETAIL PAGES =====
#main.article-list-page,
#main.article-detail-page {
    padding-top: 220px;
    font-family: $opensans;
    @media #{$break1000} {
        padding-top: 50px;
    }
    .blogHomeHeader {
        @media #{$break1000} {
            width: 100%;
            background-size: contain;
        }
        @media #{$break600} {
            height: 100px;
        }
        @media #{$break400} {
            height: 50px;
        }
    }
    .blogPagination {
        width: auto;
        margin: 0 10px;
        @media #{$break600} {
            height: auto;
        }
        .previousArticle,
        .nextArticle {
            width: auto;
            float: left;
            position: relative;
            @media #{$break400} {
                font-size: 10px;
            }
            .pagingNav {
                @media #{$break400} {
                    width: 17px;
                    margin-top: 2px;
                    font-size: 18px;
                }
            }
        }
        .nextArticle {
            margin-left: 30px;
            float: right;
        }
        .previousArticle .pagingArticle,
        .nextArticle .pagingArticle {
            width: auto;
            @media #{$break600} {
            }
        }
    }
    .row #blogMenus.three.columns {
        @media #{$break600} {
            width: 25%;
        }
        @media #{$break400} {
            width: auto;
        }
        .leftMenu {
            h3 {
                @media #{$break800} {
                    padding-left: 10px;
                }
            }
            li {
                @media #{$break400} {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    .row .nine.columns {
        width: 49%;
        @media #{$break1000} {
            width: 75%;
            margin-left: 40px;
        }
        @media #{$break600} {
            width: 65%;
            margin-left: 30px;
        }
        @media #{$break600} {
            width: 65%;
            margin-left: 20px;
        }
        @media #{$break400} {
            width: 90%;
            margin: 0 20px;
        }
        .blogHeader h2 {
            @media #{$break400} {
                font-size: 20px;
            }
        }
        div.row.author {
            width: auto;
            div.twelve.columns.italics {
                @media #{$break800} {
                    width: auto;
                }
            }
        }
    }
    .four.columns.aside#article-list-sticky-sidebar {
        width: 270px;
        margin: 0;
        float: right;
        @media #{$break1000} {
            display: none;
        }
        .sidebar-newsletter {
            width: 270px;
        }
        .socialConnect {
            margin-left: 270px;
            .catalog-newsletter {
                display: none;
            }
        }
    }
}
.addthis_toolbox {
    .columns.nine {
        width: auto;
    }
}

// ===== TAILOR MADE =====
.tailor-made-section-header {
    margin-bottom: 15px;
    padding-top: 10px;
    color: $black;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1px;
    float: none;
    @media #{$break400} {
        font-size: 32px;
        line-height: 35px;
    }
    #section-header-smaller {
        margin: 0;
        font-size: 30px;
        @media #{$break400} {
            font-size: 20px;
        }
    }
}

.request-itinerary {
    margin: 30px 0 40px 0;
    padding-bottom: 40px;
    border-bottom: solid 5px #e6e6e6;
    @media #{$break1000} {
        padding: 0 10px 40px;
        text-align: center;
    }
    button {
        margin: 12px 0 25px;
        padding: 8px 50px;
        background: $gold;
        border: none;
        border-radius: 5px;
        color: $white;
        font-size: 22px;
    }
}

#tailor-made-section-header-one {
    margin: 15px 0 10px 0;
    @media #{$break800} {
        margin-top: 0;
    }
    @media #{$break400} {
        margin-top: 10px;
    }
}

#main.landing-page .how-it-works {
    margin-bottom: 60px;
    padding-bottom: 50px;
    border-bottom: solid 5px #e6e6e6;
    @media #{$break600} {
        text-align: center;
    }
    .info-section {
        margin: 30px 0 10px;
        display: inline-block;
        vertical-align: top;
        @media #{$break600} {
            width: auto;
            padding: 0 20px;
        }
        &:nth-of-type(even) {
            margin-left: 0;
        }
        .info-thumbnail {
            width: 150px;
            height: 150px;
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
            vertical-align: top;
            @media #{$break600} {
                width: 250px;
                height: 250px;
                margin: 0 auto;
                display: block;
            }
        }
        .info-text {
            width: 75%;
            margin-left: 25px;
            display: inline-block;
            vertical-align: top;
            @media #{$break800} {
                width: 65%;
            }
            @media #{$break600} {
                width: auto;
                margin: 20px auto 0;
                display: block;
            }
            h3 {
                margin: 0 0 15px;
                font-size: 28px;
                font-weight: bold;
                letter-spacing: -1px;
            }
        }
    }
}

// ===== CONTACT PAGE =====
#main.contact-page {
    #contact-form {
        p {
            font-size: 13px;
            font-weight: bold;
        }
        input,
        textarea {
            border: solid 1px #bbb9b9;
            box-shadow: none;
        }
        fieldset {
            .field {
                @media #{$break400} {
                    width: 100%;
                    display: block;
                }
            }
            .field.last {
                @media #{$break400} {
                    margin-left: 0;
                }
            }
        }
        li input {
            margin-top: 5px;
        }
        .socialConnect {
            margin-left: 940px;
        }
    }
}

// ===== STUDENT ADVENTURES PAGE =====
#student-adventures-form {
    p {
        font-size: 13px;
        font-weight: bold;
    }
    label {
        font-size: 15px;
    }
    input {
        margin-bottom: 20px;
        border: solid 1px #bbb9b9;
        box-shadow: none;
        font-size: 20px;
    }
    fieldset {
        .field {
            @media #{$break400} {
                width: 100%;
                display: block;
            }
        }
        .field.last {
            @media #{$break400} {
                margin-left: 0;
            }
        }
    }
    li input {
        margin-top: 5px;
    }
    p button {
        width: 220px;
        height: 40px;
        margin: 20px auto;
        font-size: 18px;
        line-height: 23px;
    }
}

// ===== STUDENT TRAVEL PAGE =====
#main.student-travel-page {
    padding-top: 50px;

    @media (max-width: 1000px) {
        padding-top: 0;
    }

    .row {
        max-width: 1180px;
        @media (max-width: 1400px) {
            max-width: 1020px;
        }
        @media (max-width: 1200px) {
            max-width: 950px;
        }

        .twelve.columns {
            width: 100%;
            margin: auto;
            float: none;
            @media (max-width: 1000px) {
                width: auto;
                padding: 0 20px;
            }
            @media (max-width: 400px) {
                padding: 0 10px;
            }
        }

        h1 {
            margin-top: 20px;
            font-weight: bold;
        }

        .destination-group {
            width: 49%;
            margin: 30px 0 0 1%;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 1000px) {
                width: 100%;
                margin-left: 0;
            }
            &:first-of-type {
                margin-left: 0;
            }

            h2 {
                margin-bottom: 5px;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                @media (max-width: 1000px) {
                    margin-top: 20px;
                }
            }

            img {
                height: 270px;
                margin-bottom: 20px;
                @media (max-width: 1000px) {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;
                }
            }

            .sponsor-logos {
                height: 100px;
                text-align: center;

                img {
                    width: auto;
                    max-width: 38%;
                    height: auto;
                    margin: 0 auto 30px;
                    padding: 0 20px;
                    vertical-align: middle;
                    @media (max-width: 400px) {
                        //width: 100%;
                        max-width: 100%;
                        margin-bottom: 10px;
                        padding: 0;
                    }
                    &:first-of-type {
                        border-right: 2px solid #999;
                        @media (max-width: 400px) {
                            border: 0;
                        }
                    }
                }
            }

            p {
                width: 150px;
                font-weight: bold;
                display: inline-block;
                vertical-align: middle;
                @media (max-width: 1400px) {
                    width: 120px;
                    font-size: 14px;
                }
                @media (max-width: 600px) {
                    width: auto;
                }
            }

            a {
                color: #5091cf;
                vertical-align: top;
                @media (max-width: 600px) {
                    max-width: 100%;
                    overflow-wrap: break-word;
                }
            }
        }
    }

    a.button {
        max-width: 600px;
        margin: 50px auto;
        padding: 10px 15px;
        color: #fff;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        background-color: #5091cf;
        border-radius: 3px;
        display: block;
    }
}

// ===== REGENT READING LIST =====
.regent {
    h2 {
        @media #{$break1000} {
            text-align: center;
        }
    }
    .reading-list-featured-items {
        @media #{$break1000} {
            text-align: center;
        }
        .four.columns {
            margin-bottom: 20px;
            @media #{$break1000} {
                width: 220px;
                margin: 0 auto 30px auto;
                float: none;
                display: inline-block;
                vertical-align: top;
            }
            h2 {
                text-align: left;
                @media #{$break1000} {
                    font-size: 24px;
                }
            }
        }
    }
}

// ===== SOCIAL CONNECT =====
.homepage-sidebar .socialConnect,
.flatpage .socialConnect {
    @media #{$break1150} {
        display: none !important;
    }
}
.socialConnect p {
    color: $black !important;
}

// Hide newsletter popup on mobile
.e-newsletter-wrapper {
    display: none !important;
    @media #{$break800} {
        display: none !important;
    }
}

/* ===== TOGGLE SWITCH =====
/* The switch - the box around the slider */
.switch {
    width: 60px;
    height: 34px;
    margin: 11px 0 0 5px;
    border-radius: 100%;
    position: relative;
    float: right;
    display: inline-block;
    vertical-align: middle;
    @media #{$break1200} {
        width: 40px;
        height: 24px;
    }
}

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @media #{$break1200} {
        width: 16px;
        height: 16px;
    }
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    @media #{$break1200} {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

// Mobile menu button
#mobileMenuButton {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 11px;
    left: 10px;
    display: none;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    @media #{$break1000} {
        display: block;
    }
    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #ececec;
        border-radius: 5px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }
}

#mobileMenuButton span:nth-child(1) {
    top: 0px;
}

#mobileMenuButton span:nth-child(2),
#mobileMenuButton span:nth-child(3) {
    top: 9px;
}

#mobileMenuButton span:nth-child(4) {
    top: 18px;
}

#mobileMenuButton.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#mobileMenuButton.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#mobileMenuButton.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#mobileMenuButton.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}

/* For Windows phone */
@-ms-viewport {
    width: device-width;
}

/* For Windows phone */
@-ms-viewport {
    width: device-width;
}

/* Print styles */
@media print {
    // Global
    body {
        margin: 5mm;
    }

    .donotprint {
        display: none !important;
    }

    header,
    .contacts,
    .row.subheader,
    .boldLine,
    .fa-times,
    .catalog,
    .sidebar-newsletter,
    .socialConnect,
    footer,
    .print-hide,
    iframe,
    form#contact-form {
        display: none !important;
    }

    // Tour page
    #main {
        overflow: visible !important;
    }

    #main * {
        border: none !important;
    }

    .tourHero,
    .cycle-slideshow,
    cite.credit,
    #tour-center-menu li.main-menu,
    .tour-summary-box,
    .button,
    section.related-tours,
    button.terms-conditions,
    .itinerary-option,
    #tour-center-menu li.main-menu.active#pricing,
    #tour-center-menu li.main-menu.active#expert,
    #GTM-journeys-esri-map button.print,
    button.fullscreen {
        display: none !important;
    }

    #tour-center-menu li.main-menu.active,
    #tour-content .itinerary,
    #tour-content .tour-pricing,
    #tour-content .tour-page-experts,
    #tour-content .tour-page-details,
    #tour-content .tour-page-details section {
        display: block !important;
    }

    #tour-center-menu li.main-menu.active {
        width: auto !important;
        padding: 0 !important;
    }

    #main.tour-page #tour-content .tour-page-details .section-title,
    #main.tour-page #tour-content .tour-pricing .priceDeparture h3 {
        padding: 0;
        background: none;

        i.fa-sort-desc {
            display: none;
        }
    }

    #tour-content {
        max-width: 700px;
    }

    #tour-content .collapse {
        height: auto;
    }

    #GTM-journeys-esri-map img {
        width: 100% !important;
    }

    .red.meal-key {
        margin-top: 0 !important;
    }
}

.button {
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    display: block;
    border: none;
    outline: none;
    margin: 10px auto 6px;
    font-family: 'Helvetica', 'Helvetica Neue', Arial, Verdana, sans-serif;
}

.button.black {
    color: #fff;
    border-radius: 5px;
    background: #3e3e3e; /* non-css3 browsers */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#3e3e3e),
        to(#111111)
    ); /* webkit */
    background: -moz-linear-gradient(top, #3e3e3e, #111111); /* firefox 3.6+ */
}

.button.black:hover {
    color: #fff;
    text-decoration: none;
    background: #616161; /* non-css3 browsers */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#616161),
        to(#373737)
    ); /* webkit */
    background: -moz-linear-gradient(top, #616161, #373737); /* firefox 3.6+ */
}

.button.small,
.button .small {
    font-size: 11px;
    font-weight: bold;
    width: 135px;
    height: 27px;
    line-height: 2.4;
}

.button.large,
.button .large {
    font-size: 17px;
    font-weight: bold;
    width: 219px;
    height: 39px;
    line-height: 2.4;
    letter-spacing: 0.5px;
}

.additional-expert {
    clear: both;
    padding: 15px 0;
    min-height: 150px;
    p {
        margin-top: 0;
    }
}

.fieldWrapper {
    label {
        em {
            font-weight: normal !important;
        }
    }
}
