header {
    width: 100%;
    background: $black;
    color: $white;
    font-family: $opensans;
    display: unset;
    z-index: 999;
    .travelAlert {
        background: #ffd400;
        padding: 5px 0;
        position: relative;
        svg,
        i {
            font-weight: normal;
            position: absolute;
            top: 9px;
            left: 10px;
            display: block;
            color: $black;
            width: 16px;
            height: auto;
            cursor: pointer;
        }
        p {
            margin: 0;
            padding: 0 30px;
            text-align: center;
            color: $black;
            a {
                color: $black;
                text-decoration: underline;
            }
        }
    }
    .header-container-wrapper {
        width: 100%;
        background-color: black;
        position: sticky;
        top: 0;
        z-index: 999;
    }
    .headerContainer {
        max-width: 1300px;
        margin: 0 auto;
        background-color: black;
        display: grid;
        grid-template-columns: 300px 1fr;
        align-items: center;
        @media (max-width: 1300px) {
            width: auto;
            grid-template-columns: 24vw 1fr;
        }
        @media (max-width: 1000px) {
            height: 50px;
            position: relative;
            display: block;
        }
        #mobileMenuButton {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 14px;
            left: 15px;
            display: none;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            @media #{$break1000} {
                display: block;
            }
            span {
                display: block;
                position: absolute;
                height: 4px;
                width: 100%;
                background: #ececec;
                border-radius: 5px;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: 0.25s ease-in-out;
                -moz-transition: 0.25s ease-in-out;
                -o-transition: 0.25s ease-in-out;
                transition: 0.25s ease-in-out;
            }
            span:nth-child(1) {
                top: 0px;
            }
            span:nth-child(2),
            span:nth-child(3) {
                top: 9px;
            }
            span:nth-child(4) {
                top: 18px;
            }
            .open {
                span:nth-child(1) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
                span:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                span:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                span:nth-child(4) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
        .logo {
            border-right: 1px solid #4d4d4d;
            @media (max-width: 1000px) {
                text-align: center;
            }
            img {
                width: 272px;
                height: auto;
                @media (max-width: 1300px) {
                    width: 23vw;
                }
                @media (max-width: 1000px) {
                    width: 150px;
                }
            }
        }
        .menu {
            @media (max-width: 1000px) {
                width: 400px;
                margin-top: -20px;
                margin-left: -200vw;
                padding-bottom: 200vh;
                background-color: black;
                transition: margin-left 0.5s;
            }
            @media (max-width: 400px) {
                width: 100vw;
            }
            &.open {
                margin-left: 0;
            }
            .menuItems {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr;
                justify-content: right;
                @media (max-width: 1000px) {
                    margin: auto;
                    padding: 20px 0;
                    background-color: black;
                    display: block;
                }
                .action {
                    border-bottom: 1px solid #4d4d4d;
                    text-align: right;
                    padding: 6px 0;
                    font-size: 1.1rem;
                    font-family: $opensans;
                    @media (max-width: 1310px) {
                        font-size: 1.3vw;
                        text-align: left;
                    }
                    @media (max-width: 1000px) {
                        width: 300px;
                        margin: auto;
                        font-size: 1.1rem;
                        border-bottom: none;
                    }
                    .operatingHours {
                        color: #a8a8a8;
                    }
                    svg,
                    i {
                        color: $gold;
                        margin-left: 20px;
                        width: 17px;
                        height: auto;
                        @media (max-width: 1000px) {
                            margin: 0 5px 0 10px;
                        }
                    }
                    svg.fa-instagram,
                    i.fa-instagram {
                        margin-left: 0;
                        position: relative;
                        top: 1px;
                    }
                    i {
                        font-size: 1rem;
                    }
                    br {
                        display: none;
                        @media (max-width: 1000px) {
                            display: block;
                        }
                    }
                    button {
                        background: $gold;
                        border: 0;
                        text-transform: uppercase;
                        margin-left: 20px;
                        @media (max-width: 1000px) {
                            display: none;
                        }
                        &:hover {
                            background: $darkgold;
                        }
                        a {
                            text-decoration: none;
                            color: $black;
                            padding: 9px 20px;
                            display: block;
                            font-size: 0.95rem;
                            font-weight: 600;
                            @media (max-width: 1300px) {
                                padding: 0.6vw 0.8vw;
                                font-size: 1.1vw;
                            }
                        }
                    }
                }
                .navigation {
                    text-align: right;
                    @media (max-width: 1310px) {
                        margin: 10px;
                        text-align: left;
                    }
                    @media (max-width: 1000px) {
                        margin: 10px 0;
                    }
                    nav {
                        display: inline-block;
                        .menu {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            @media (min-width: 400px) {
                                li:focus-within > ul,
                                li:hover > ul {
                                    top: auto;
                                    left: auto;
                                    margin-left: 0;
                                    max-width: 300px;
                                }
                            }
                            ul {
                                height: 0;
                                min-width: 150px;
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                overflow: hidden;
                                background-color: $black;
                                @media (min-width: 1000px) {
                                    height: auto;
                                    overflow: visible;
                                    position: absolute;
                                    top: -999em;
                                    left: -999em;
                                }
                            }
                            li {
                                display: inline-block;
                                position: relative;
                                text-align: left;
                                @media (max-width: 1000px) {
                                    border-bottom: 1px solid #222;
                                    display: block;
                                    &:first-of-type {
                                        display: none;
                                    }
                                    &:nth-of-type(2) a {
                                        width: 260px;
                                        margin: 20px auto;
                                        padding: 8px 10px;
                                        background-color: $gold;
                                        color: black;
                                        font-weight: bold;
                                        text-align: center;
                                        text-transform: uppercase;
                                        letter-spacing: 2px;
                                        display: block;
                                    }
                                }
                                @media (max-width: 400px) {
                                    text-align: center;
                                }
                                .menu.sub-open {
                                    height: auto;
                                }
                                li {
                                    &:focus-within,
                                    &:hover {
                                        background: #2d2d2d;
                                    }
                                    @media (max-width: 1000px) {
                                        width: 300px;
                                        margin: auto;
                                        &:first-of-type {
                                            display: block;
                                        }
                                        &:nth-of-type(2) a {
                                            width: 300px;
                                            margin: auto;
                                            padding: 0.4rem 0.9rem;
                                            background-color: inherit;
                                            color: white;
                                            font-weight: normal;
                                            text-align: left;
                                            text-transform: none;
                                            letter-spacing: 0;
                                            display: block;
                                        }
                                    }
                                    a {
                                        padding: 0.4rem 0.9rem;
                                        font-size: 0.9rem;
                                    }
                                }
                                .fa-plus,
                                .fa-minus {
                                    color: #ececec;
                                    position: absolute;
                                    top: 15px;
                                    right: 60px;
                                    cursor: pointer;
                                    display: none;
                                    z-index: 999;
                                    @media (max-width: 1000px) {
                                        display: block;
                                    }
                                    @media (max-width: 400px) {
                                        right: 13vw;
                                    }
                                }
                            }
                            li:focus-within > ul,
                            li:hover > ul {
                                height: auto;
                                margin-left: auto;
                                @media (max-width: 1300px) {
                                    margin-left: 0;
                                }
                                @media (max-width: 1000px) {
                                    height: 0;
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                            }
                            @media (min-width: 400px) {
                                li li {
                                    left: auto;
                                    top: auto;
                                    display: block;
                                }
                                li li:focus-within > ul,
                                li li:hover > ul {
                                    left: 100%;
                                    top: 0;
                                }
                            }
                            a {
                                display: block;
                                white-space: nowrap;
                                color: $white;
                                font-size: 1.1rem;
                                text-decoration: none;
                                padding: 1rem 0.6rem;
                                @media (max-width: 1300px) {
                                    padding: 1vw 0.7vw;
                                    font-size: 1.25vw;
                                }
                                @media (max-width: 1000px) {
                                    padding: 10px 60px;
                                    font-size: 1.2rem;
                                    text-align: left;
                                    display: inline-block;
                                }
                                @media (max-width: 400px) {
                                    width: 200px;
                                    margin: auto;
                                    margin-right: 80px;
                                    padding: 10px 20px;
                                }
                            }
                        }
                    }
                    fieldset {
                        display: inline-block;
                        border: 0;
                        padding: 0;
                        margin-top: 3px;
                        @media (max-width: 1000px) {
                            text-align: center;
                            display: block;
                        }
                        input[type='submit'] {
                            background: #2d2d2d;
                            color: $white;
                            border: 0;
                            padding: 8px;
                            position: relative;
                            top: -2px;
                            @media (max-width: 1000px) {
                                padding: 14px 8px;
                            }
                        }
                        input#id_q {
                            border: 0;
                            background: #2d2d2d;
                            color: $white;
                            padding: 8px;
                            position: relative;
                            top: -2px;
                            @media (max-width: 1000px) {
                                width: 222px;
                                padding: 13px 10px;
                                font-size: 1rem;
                            }
                        }
                        input#id_q::placeholder {
                            color: $white;
                        }
                        input#id_q::-webkit-input-placeholder {
                            color: $white;
                        }
                        input#id_q:-moz-placeholder {
                            color: $white;
                        }
                        input#id_q::-moz-placeholder {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    a {
        color: $white;
    }
}
