.gallery-list-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 20px 40px;
    .boldLineFullGray {
        width: 100%;
        height: 5px;
        background: #dcdcdc;
    }
    .galleryList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
        }
        h3 {
            a {
                color: $black;
            }
            margin-bottom: 40px;
        }
    }
}

.gallery-detail-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 20px 70px;
    .back-to-galleries {
        a {
            color: $darkgold;
            font-weight: bold;
        }
    }
    .gallery-related-tours {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
        }
        h3 {
            a {
                color: $black;
            }
        }
    }
}
