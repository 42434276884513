.twoPromos {
    margin: 30px auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
    h3 {
        a {
            color: $darkgold;
        }
    }
    img {
        width: 100%;
        height: auto;
    }
}

.travelStyles {
    border-top: 5px solid $border;
    .ourTravelstyles {
        margin-bottom: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
            height: auto;
        }
        h3 {
            a {
                color: $darkgold;
            }
        }
    }
}
.lowerHomecontainer {
    border-top: 5px solid $border;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 270px;
    position: relative;
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    .lowerHomeleft {
        .specialOffers {
            margin-bottom: 50px;
            .specialOfferswrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;
                @media (max-width: 800px) {
                    grid-template-columns: 1fr;
                }
                h3 {
                    a {
                        color: $darkgold;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
            a[role='button'] {
                margin-top: 50px;
                padding: 7px 0;
                background: #9c7928;
                text-transform: none;
                color: #fff;
                border-radius: 5px;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                display: block;
                border: none;
                outline: none;
                margin: 30px auto 0;
            }
        }
    }
    .lowerHomeright {
        @media (max-width: 1000px) {
            display: none;
        }
    }
    aside {
        position: sticky;
        position: -webkit-sticky;
        top: 180px;
        margin-top: 75px;
        .catalog {
            img {
                width: 100%;
                height: auto;
            }
            h3 {
                margin-bottom: 15px;
                a {
                    color: $darkgold;
                }
            }
        }
    }

    .lowerHomecontainer {
        border-top: 5px solid $border;
        display: grid;
        grid-gap: 40px;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .journeysExperts {
        border-top: 5px solid $border;
        .expertList {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            @media (max-width: 600px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 400px) {
                grid-template-columns: repeat(2, 1fr);
            }
            .expert {
                text-align: center;
                h3 {
                    font-size: 1rem;
                    font-family: $opensans;
                    font-weight: bold;
                    a {
                        color: $darkgold;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                }
                h3 {
                    margin-top: 0;
                    @media (max-width: 400px) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .journeysGalleries {
        margin-bottom: 50px;
        border-top: 5px solid $border;
        h2 {
            color: $darkgold;
        }
        .slideshow {
            height: 300px;
            @media (max-width: 1000px) {
                height: 44vw;
            }
        }
    }
    button {
        background: $gold;
        color: $white;
        border-radius: 7px;
        border: 1px solid $gold;
        padding: 3px 35px;
        font-size: 1rem;
        width: 100%;
        a {
            color: $white;
            border-radius: 7px;
            border: 1px solid $darkgold;
            padding: 3px 35px;
            font-size: 1rem;
            width: 100%;
            a {
                color: $white;
                display: block;
                width: 100%;
            }
        }
    }

    .journeysFeatureditem {
        p {
            line-height: 1.55rem;
            margin-bottom: 0;
            &.morelink {
                margin-top: 5px;
            }
        }
        a[role='button'] {
            color: $darkgold;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 700;
            font-size: 0.8rem;
        }
    }
}
