.blogWrapper {
    width: 960px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 40px;
    position: relative;
    @media (max-width: 1000px) {
        width: 100%;
    }
    .journeys-dispatches {
        width: 100%;
        height: auto;
    }
    h1.headline {
        margin: 0;
        padding-bottom: 0;
    }
    p {
        line-height: 1.75rem;
    }
    a {
        text-decoration: underline;
    }
    .column {
        @media (max-width: 1000px) {
            img {
                width: 100%;
                height: auto;
            }
        }
        &:first-of-type {
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .widget-blog-wrapper {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-gap: 30px;
        margin-top: 20px;
        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
            padding: 20px;
        }
        @media (max-width: 1000px) {
            .column {
                &:last-of-type {
                    display: none;
                }
            }
        }
        .sticky {
            position: sticky;
            top: 220px;
            @media (max-width: 1000px) {
                display: none;
            }
        }
    }
}
