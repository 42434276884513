.tailorMadeBody,
.tailor-made {
    .landingSlideshow {
        padding-bottom: 20px;
    }
    p {
        line-height: 1.6rem;
    }
    .country-list {
        margin-top: 40px;
        padding-top: 40px;
        border-top: solid 5px #d1d1d1;
        font-size: 2.4rem;
        @media (max-width: 600px) {
            text-align: center;
        }
        &:hover {
            cursor: pointer;
        }
        .svg-inline--fa {
            margin: 0 0 0 10px;
            color: $darkgold;

            @media (max-width: 600px) {
                font-size: 6vw;
            }
            &.fa-sort-down {
                vertical-align: 0.15rem;
            }
            &.fa-sort-up {
                vertical-align: -0.5rem;
            }
        }
    }
    .tailor-made-countries {
        padding-bottom: 30px;
        display: none;
        @media (max-width: 600px) {
            text-align: center;
        }
        .countryCols {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;
            @media (max-width: 600px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .column {
            p {
                margin: 5px 0;
                a {
                    color: $darkgold;
                }
            }
            strong {
                color: black;
                a {
                    color: black;
                }
            }
            p.region {
                font-weight: bold;
            }
        }
    }
    .startYourjourney {
        border-top: 5px solid #d1d1d1;
        margin-top: 40px;
        padding-top: 20px;
        button {
            margin: 5px 0 8px;
            padding: 8px 50px;
            background: $darkgold;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 1.4rem;
            &:hover {
                background: black;
            }
        }
    }
    .landingTours {
        border-top: 5px solid #d1d1d1;
        margin-top: 40px;
        padding-top: 20px;
    }
    .howItworks {
        border-top: 5px solid #d1d1d1;
        margin-top: 30px;
        padding-top: 20px;
        padding-bottom: 25px;
        .imageLeft-textRight img {
            width: 150px;
        }
        .imageLeft-textRight h3 {
            color: black;
        }
    }
}

.tailor-made-section-header,
.tailor-made-more-link,
.tailor-made-small-thumbnail-containers,
#ready-to-start {
    @media (max-width: 400px) {
        padding: 0 10px;
    }
}
.tailor-made-small-thumbnail-containers {
    @media (max-width: 800px) {
        height: auto;
        margin-top: 30px;
        text-align: center;
    }
}
#main.audley-flatpage {
    p {
        @media (max-width: 400px) {
            padding: 0 10px;
        }
    }
    table {
        width: 100%;
        table-layout: fixed;
        td {
            padding: 10px 15px;
            @media (max-width: 800px) {
                padding: 5px 10px;
            }
            @media (max-width: 600px) {
                padding: 5px;
            }
            @media (max-width: 400px) {
                padding: 1px 2px;
            }
            p {
                padding-bottom: 0;
                font-size: 14px;
                @media (max-width: 600px) {
                    font-size: 10px;
                    line-height: 15px;
                }
                @media (max-width: 400px) {
                    padding: 0 !important;
                    font-size: 9px;
                }
            }
        }
    }
}

// ===== TAILOR MADE INQUIRE =====
#main.tailor-made-inquire-page {
    .containerFixed {
        max-width: 960px;
        margin: 0 auto;
        padding: 50px;
    }
    .tailor-made-inquire-page-break {
        height: 1px;
        margin: 30px 0 30px 0;
        background: #dcdcdc;
    }
    .tailor-made-inquire-custom-journey {
        color: #5ba7bd;
        font-family: 'Open sans', sans-serif;
        font-size: 23px;
        @media (max-width: 400px) {
            padding: 0 10px;
        }
    }
    .tailor-made-inquire-page-indicator {
        display: none;
        @media (max-width: 800px) {
            margin: 30px 0;
        }
        .tailor-made-inquire-active-page-line {
            @media (max-width: 800px) {
                width: 170px;
            }
        }
        .tailor-made-inquire-travel-plans {
            margin: 0 0 15px 0;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            display: inline-block;
        }
        .tailor-made-inquire-your-details {
            position: absolute;
            left: 320px;
            @media (max-width: 800px) {
                left: 110px;
            }
        }
    }
    .tailor-made-inquire-form {
        margin: 20px 0 0 0;
        font-family: Arial, 'Open sans', sans-serif;
        @media (max-width: 500px) {
            margin: -20px 0 -70px -23px;
            @include transform(scale(0.9));
        }
        @media (max-width: 500px) {
            margin: -110px 0 -180px -64px;
            @include transform(scale(0.7));
        }
        .tailor-made-inquire-form-line {
            margin: 20px 0;
        }
        .tailor-made-inquire-your-travel-plans {
            margin: 0 0 15px 0;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            display: inline-block;
        }
        .tailor-made-inquire-required {
            margin: 0 0 0 5px;
            color: #b5b5b5;
            font-style: italic;
            display: inline-block;
        }
        .tailor-made-inquire-label {
            width: 150px;
            margin: 10px 0;
            color: #333;
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            @media (max-width: 800px) {
                width: auto;
                display: block;
            }
            span.required {
                color: #c00;
            }
        }
        .tailor-made-inquire-form-right-column {
            display: inline-block;
            vertical-align: middle;
            @media (max-width: 800px) {
                margin: 0;
            }
            input {
                @media (max-width: 500px) {
                }
            }
            select {
                width: 100%;
                padding: 5px;
            }
            .tailor-made-inquire-red-required-text {
                color: #f15d5d;
                font-style: italic;
                font-size: 0.8rem;
                clear: both;
            }
        }
        .tailor-made-inquire-red-required-text {
            .desktop {
                @media (max-width: 1000px) {
                    display: none;
                }
            }
            .mobile {
                display: none;
                @media (max-width: 1000px) {
                    display: block;
                }
            }
        }
        .tailor-made-inquire-form-continue-button {
            width: 220px;
            margin: 20px auto;
            padding: 8px 10px 5px 10px;
            background: #5ba7bd;
            border: none;
            border-radius: 8px;
            color: #ffffff;
            font-size: 22px;
            line-height: 30px;
            text-align: left;
            @media (max-width: 800px) {
                margin: 30px 0 0 0;
            }
        }
        .tailor-made-inquire-form-optional-label {
            margin: 0 3px 0 0;
            color: #a09f9f;
            font-size: 15px;
            font-style: italic;
            font-weight: 400;
        }
    }
    // Inquire next
    #tailor-made-inquire-next-form {
        margin: 20px 0 0 0;
        @include transform(scale(1));
        .tailor-made-inquire-form-name-prefix select {
            width: 90px !important;
            height: 31px;
            border: solid 1px #ccc;
        }
        .tailor-made-inquire-form-right-column {
            @media (max-width: 500px) {
                width: 100%;
            }
            select {
                width: 100%;
                padding: 5px;
                font-size: 16px;
            }
            input,
            textarea {
                @media (max-width: 500px) {
                    width: 98%;
                    margin-top: 3px;
                }
            }
        }
        .tailor-made-inquire-form-travelers {
            input,
            textarea {
                @media (max-width: 500px) {
                    width: auto;
                    margin: -2px 5px 12px 0;
                }
            }
        }
        #tailor-made-inquire-form-back-button {
            @media (max-width: 400px) {
                margin: 30px 0 0 0;
                float: none;
            }
        }
        #tailor-made-inquire-form-submit-button {
            width: 220px;
            margin: 68px 0 0 45px;
            padding: 8px 10px 5px 10px;
            background: #5ba7bd;
            border: none;
            border-radius: 8px;
            color: #ffffff;
            font-size: 22px;
            line-height: 30px;
            text-align: left;
        }
        #tailor-made-inquire-form-submit-button,
        #tailor-made-inquire-form-privacy-promise {
            @media (max-width: 400px) {
                margin: 30px 0 0 0;
            }
        }
    }
    .row .four.columns.aside {
        @media (max-width: 800px) {
            display: none;
        }
        section.catalog,
        .sidebar-newsletter {
            display: none;
        }
        .socialConnect {
            margin-left: 160px;
        }
    }
}

.tailor-made-inquire-page-break {
    width: 700px;
    height: 1px;
    margin: 30px 0 30px 0;
    background: #dcdcdc;
}
.tailor-made-inquire-custom-journey {
    color: #5ba7bd;
    font-family: 'Open sans', sans-serif;
    font-size: 23px;
}
.tailor-made-inquire-page-indicator {
    margin: 30px 170px;
}
.tailor-made-inquire-page-line {
    width: 70px;
    height: 1px;
    background: #5ba7bd;
    display: inline-block;
}
.tailor-made-inquire-active-page-line {
    width: 200px;
    margin: 0 0 0 -3px;
    background: #cccccc;
}
.tailor-made-inquire-active-page-line-blue {
    background: #5ba7bd;
}
.tailor-made-inquire-page-dot {
    width: 20px;
    height: 20px;
    margin: 2px 0 0 -3px;
    background: #5ba7bd;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
}
.tailor-made-inquire-inactive-page-dot {
    background: #cccccc;
    box-shadow: inset 0 0 2px #333;
}
.tailor-made-inquire-your-plans-details {
    font-family: Arial, 'Open sans', sans-serif;
    font-size: 12px;
}
.tailor-made-inquire-travel-plans {
    margin: 5px 0 0 17px;
}
.tailor-made-inquire-your-details {
    margin: 5px 0 0 110px;
}
.tailor-made-inquire-travel-details-inactive {
    color: #b5b5b5;
}
.tailor-made-inquire-form {
    margin: 20px 0 0 0;
    font-family: Arial, 'Open sans', sans-serif;
}
.tailor-made-inquire-form a {
    text-decoration: none;
}
.tailor-made-inquire-label {
    color: #333;
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    display: inline-block;
    @media (max-width: 800px) {
        text-align: left;
    }
}
#tailor-made-inquire-form-one select {
    width: 250px;
    text-transform: none;
}
.tailor-made-inquire-form-buttons {
    margin: 6px 0 5px 5px;
    float: left;
}
.tailor-made-inquire-form-buttons input {
    display: none;
    /* IE 6-10 */
    display: block\9;
    position: absolute;
    z-index: -1;
}
.tailor-made-inquire-form-buttons label {
    height: 18px;
    border: solid 1px #888;
    background: #f3f3f3;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ffffff),
        to(#dcdcdc)
    );
    background-image: -webkit-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: -moz-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: -o-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: linear-gradient(to bottom, #ffffff, #dcdcdc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    color: #333333;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    margin-left: -5px;
    padding: 6px 10px 4px 10px;
    font-size: 13px;
    font-weight: lighter;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
}
.tailor-made-inquire-form-buttons label:hover {
    background: #ddd;
}
.tailor-made-inquire-form-buttons label:active,
.tailor-made-inquire-form-buttons input:focus + label {
    background-color: #aaa;
}
.tailor-made-inquire-form-buttons label.checked {
    background: #fff;
    -webkit-box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    filter: none;
}
.button-label-font-awesome-icon {
    font-family: FontAwesome;
    font-size: 15px;
}
.button-label-font-awesome-icon-stars {
    padding: 3px 10px 7px 10px !important;
}
#id_duration,
#id_when,
#id_travel_reason,
#id_party_size,
#id_accomodations {
    font-family: Arial, sans-serif;
}
#id_duration:first-child {
    display: none;
}
#id_when:first-child:before,
#id_when:nth-child(2):before {
    content: '\f017';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_when:nth-child(3):before {
    content: '\f059';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_when:nth-child(4):before {
    content: '\f073';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_party_size:nth-child(1):before {
    content: '\f007';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_party_size:nth-child(2):before {
    content: '\f182\f183';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_party_size:nth-child(3):before {
    content: '\f0c0';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
#id_accomodations:nth-child(1):before {
    content: '\f005\f005\f005';
    font-family: FontAwesome;
    font-size: 7px;
    margin: 0 5px 0 0;
    margin: 0 4px 0 0;
    vertical-align: 10%;
    color: #000;
    visibility: visible;
}
#id_accomodations:nth-child(2):before {
    content: '\f005\f005\f005\f005';
    width: 5px;
    height: 5px;
    font-family: FontAwesome;
    font-size: 7px;
    margin: 0 4px 0 0;
    vertical-align: 10%;
    visibility: visible;
}
#id_accomodations:nth-child(3):before {
    content: '\f005\f005\f005\f005\f005';
    font-family: FontAwesome;
    font-size: 7px;
    margin: 0 1.8px 0 0;
    margin: 0 4px 0 0;
    vertical-align: 10%;
    color: #000;
    visibility: visible;
}
#id_accomodations:nth-child(4):before {
    content: '\f059';
    margin: 0 5px 0 0;
    font-family: FontAwesome;
}
.tailor-made-inquire-your-travel-plans {
    margin: 0 0 15px 0;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
}
.tailor-made-inquire-required {
    margin: 0 0 0 5px;
    color: #b5b5b5;
    font-style: italic;
    display: inline-block;
}
.tailor-made-inquire-form-line {
    margin: 20px 0 0 0;
}
.tailor-made-inquire-form-right-column {
    margin: -5px 0 0 8px;
    display: inline-block;
}
.tailor-made-inquire-red-required-text {
    color: #f15d5d;
    font-style: italic;
    clear: both;
}
.tailor-made-inquire-black-required-text {
    color: #000;
    font-style: italic;
    clear: both;
}
.tailor-made-inquire-black-required-text a {
    text-decoration: underline;
}
.tailor-made-inquire-form-optional-label {
    margin: 0 3px 0 0;
    color: #a09f9f;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}
.tailor-made-inquire-form-right-column textarea {
    width: 420px;
    height: 100px;
    padding: 5px;
    float: left;
    border: solid 1px #000;
    border-radius: 3px;
    font-size: 13px;
}
.tailor-made-inquire-form-continue-button {
    width: 210px;
    height: 45px;
    margin: 100px 0 0 165px;
    padding: 5px 25px 0 25px !important;
    clear: both;
    background: #5ba7bd !important;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-size: 21px !important;
    text-align: left;
}
.tailor-made-inquire-form-continue-button:after {
    content: '\f0a9';
    margin: 0 20px 0 0;
    font-family: FontAwesome;
    font-size: 30px;
    float: right;
}
/* ----------------------------------------
  * Audley Inquire Page Two
  * ----------------------------------------*/
.tailor-made-inquire-next-form input {
    margin-left: 0;
}
.tailor-made-inquire-form-name-prefix select {
    width: 70px !important;
    background: #ffffff;
}
.tailor-made-inquire-form-right-column input {
    width: 180px;
    height: 20px;
    padding: 3px 5px 4px 5px;
    border: solid 1px #000;
    border-radius: 5px;
    font-size: 15px;
    vertical-align: top;
}
/* Firefox Only */
@-moz-document url-prefix() {
    .tailor-made-inquire-form-name-input {
        padding: 3px 5px 3px 5px !important;
    }
}
/* IE 6-10 */
.tailor-made-inquire-form-name-input {
    width: 180px\9 !important;
    padding: 3px\9 !important;
}
.tailor-made-inquire-form-long input {
    width: 350px;
    padding: 0 5px 6px 5px;
}
.tailor-made-inquire-form-country select {
    width: 300px;
    background: #5ba7bd;
    /* IE 6-10 */
    background: #ffffff\9;
    color: #ffffff;
    font-weight: 600;
}
.tailor-made-inquire-form-question-text {
    margin: 5px 0 5px 0;
    font-size: 15px;
}
#tailor-made-inquire-form-travelers-input {
    width: 160px;
    margin: 0 0 10px 55px;
}
.tailor-made-inquire-form-travelers input {
    width: 25px;
    margin: -2px 0 2px 20px;
    /* IE 6-10 */
    margin: -5px 0 5px 20px\9;
    border: none;
}
#tailor-made-inquire-form-travelers-input {
    border: solid 1px #cccccc;
}
.tailor-made-inquire-form-line-travelers-age {
    margin: 20px 0 20px 0;
}
.tailor-made-inquire-form-line-travelers-age input {
    width: 20px;
}
.tailor-made-inquire-form-travelers-age {
    margin: 3px 0 0 8px;
}
.tailor-made-inquire-form-travelers-age input {
    border: none;
    margin: -2px 0 2px 0;
    /* IE 6-10 */
    margin: -5px 0 5px 0\9;
}
#brochures-button-one-label {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 600;
}
#brochures-button-label:before {
    content: '\f0e0';
    font-family: FontAwesome;
    margin: 0 5px 0 0;
}
#brochures-button-label:hover,
#brochures-button:checked + #brochures-button-label {
    -webkit-box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow:
        inset 0 1px 6px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
}
#brochures-button:checked + #brochures-button-label {
    display: none;
}
#tailor-made-inquire-form-updates-input {
    display: inline-block;
}
#tailor-made-inquire-form-updates-text {
    display: inline-block;
    vertical-align: middle;
}
#tailor-made-inquire-form-back-button {
    width: 50px;
    height: 17px;
    margin: 70px 0 0 0;
    padding: 5px 10px;
    background: #f3f3f3;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ffffff),
        to(#dcdcdc)
    );
    background-image: -webkit-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: -moz-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: -o-linear-gradient(top, #ffffff, #dcdcdc);
    background-image: linear-gradient(to bottom, #ffffff, #dcdcdc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    -webkit-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.2),
        0 1px 2px rgba(0, 0, 0, 0.05);
    color: #333333;
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    float: left;
}
#tailor-made-inquire-form-back-button:before {
    content: '\f053';
    margin: 0 10px 0 0;
    font-family: FontAwesome;
}
#tailor-made-inquire-form-submit-button {
    width: 220px;
    margin: 68px 0 0 45px;
    padding: 8px 10px 5px 10px;
    background: #5ba7bd;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    font-size: 22px;
    line-height: 30px;
    text-align: left;
}
#tailor-made-inquire-form-submit-button:after {
    content: '\f0a9';
    margin: 0;
    font-family: FontAwesome;
    font-size: 30px;
    float: right;
    position: relative;
    margin-top: -2px;
}
#tailor-made-inquire-form-privacy-promise {
    margin: 10px 0 200px 112px;
    color: #4a994a;
    font-size: 11px;
}

/* ----------------------------------------
  * Audley Inquire Thanks Page
  * ----------------------------------------*/
.download-brochure-button {
    width: 210px;
    margin: 30px auto;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
}

.tailor-made-thanks {
    button {
        color: $white;
        border-radius: 7px;
        padding: 3px 35px;
        font-size: 1rem;
        border: 1px solid $darkgold;
        background: $darkgold;
        text-align: center;
        a {
            color: $white;
            border-radius: 7px;
            text-decoration: none;
            padding: 3px 35px;
            font-size: 1rem;
            width: 100%;
            a {
                color: $white;
                display: block;
                width: 100%;
            }
        }
    }
}

.g-recaptcha {
    margin-left: 67px;
}
