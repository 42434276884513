.privacyBodyColumns {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-gap: 40px;
    margin-top: 20px;
    margin-bottom: 60px;
    p {
        line-height: 1.6rem;
        a {
            color: $darkgold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .privacyMenu {
        margin-top: 30px;
        background: #f5f5f5;
        padding: 20px;
        h3 {
            margin-top: 0;
            padding-top: 0;
        }
        a {
            display: block;
            margin: 10px 0;
        }
    }

    .two-column-grid {
        margin: 30px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        a {
            height: 60px;
            padding: 20px 50px;
            background: #000;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
