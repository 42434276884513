#main.tour-page {
    @media (max-width: 1000px) {
        padding: 0 20px;
    }

    @media (max-width: 600px) {
        padding: 0;
    }

    .slideshow {
        @media (max-width: 600px) {
            .slide {
                top: 0;
                bottom: inherit;

                img {
                    height: 45vw;
                }
            }
        }
    }

    .containerFixed {
        max-width: none;
        background: white;
        .row {
            max-width: 960px;
            margin: 0 auto;
        }
    }
    .red,
    .redtext,
    .redbold {
        color: #c00;
    }
    .tourSlideshow {
        .slideshow {
            .slide .slideshow-caption {
                max-width: 80%;
            }
        }

        .row.utilityBar {
            margin-top: -34px;
            float: right;
            @media (max-width: 800px) {
                margin-top: 10px;
                float: none;
            }
        }
    }
    .tourHero {
        .four.columns.push-twelve {
            display: none;
        }
        .pull-four {
            width: 100%;
            right: inherit;
            margin: 0;
            iframe {
                width: 100%;
                position: relative;
                z-index: 9;
                @media (max-width: 800px) {
                    height: 350px;
                }
                @media (max-width: 600px) {
                    height: 250px;
                }
                @media (max-width: 400px) {
                    height: 180px;
                }
            }
            .row.utilityBar {
                width: 320px;
                padding: 10px 0 4px 0;
                background: white;
                float: right;
                @media (max-width: 1000px) {
                    float: none;
                }
                .columns.twelve {
                    width: 200px;
                    @media (max-width: 400px) {
                        width: 180px;
                    }
                }
                .share-top-links.columns {
                    float: none;
                    span {
                        width: 15px;
                        height: 32px;
                        margin: 0 0 0 15px;
                        padding-left: 17px;
                        background-size: cover;
                    }
                    .addthis_button_print.at300b span {
                        height: 100px;
                        margin-top: -46px;
                        margin-bottom: -25px;
                    }
                }
            }
            .credit {
                margin-top: 20px;
                font-family: $opensans;
                font-size: 16px;
                display: inline-block;
            }
        }
    }
    .boldLine {
        margin: 10px 0 20px 0;
        border-bottom: 4px solid #e6e6e6;
    }
    .tourTitle {
        width: 650px;
        padding: 0;
        font-family: $libreBaskerville;
        display: inline-block;
        @media (max-width: 1000px) {
            width: 550px;
        }
        @media (max-width: 900px) {
            width: 450px;
        }
        @media (max-width: 800px) {
            width: 350px;
        }
        @media (max-width: 700px) {
            width: 250px;
        }
        @media (max-width: 600px) {
            width: auto;
            margin-bottom: 20px;
        }
        h1 {
            padding: 0;
            border: none;
            font-size: 42px;
            letter-spacing: -1px;
            margin-top: 0;
            @media (max-width: 800px) {
                margin-bottom: -10px;
                font-size: 35px;
                line-height: 35px;
            }
        }
        h2 {
            margin: 5px 0;
            font-size: 25px;
        }
        h3 {
            margin: 10px 0;
            font-size: 18px;
            color: $darkgold;
            span {
                font-style: normal;
            }
        }
        h4 {
            margin: 30px 0 10px 0;
            color: $gold;
            font-size: 18px;
            font-weight: bold;
            display: inline-block;
            @media (max-width: 600px) {
                margin: 20px 0 0 0;
                display: block;
            }
        }
        .button {
            width: 200px;
            margin: 15px 0 0 0;
            padding: 7px 15px 5px 15px;
            background: $darkgold;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 20px;
            text-transform: none;
            a {
                color: white;
            }
        }
        span {
            font-size: 18px;
            @media (max-width: 600px) {
                display: none;
            }
        }
        p {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .tour-summary-box {
        width: 220px;
        padding: 20px 40px;
        background: #f0f0f0;
        font-family: $opensans;
        text-align: center;
        float: right;
        display: inline-block;
        @media (max-width: 600px) {
            width: auto;
            padding: 20px 30px;
        }
        .bookTour {
            background: #f0f0f0;
        }
        h3 {
            margin-bottom: 8px;
            font-size: 22px;
            font-weight: bold;
        }
        h4 {
            margin-top: 8px;
            margin-bottom: 0;
        }
        select {
            width: 100%;
            height: 32px;
            margin: 4px 0;
            background: #858585;
            color: white;
            font-size: 17px;
            option {
                text-transform: capitalize;
            }
        }
        button {
            width: 100%;
            height: 32px;
            margin: 4px 0;
            background: $darkgold;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 16px;
        }
        button.black {
            background: $black;
        }
        h4 {
            color: #858585;
            font-size: 20px;
            font-weight: bold;
        }
    }
    #tourMenus.three.columns {
        display: none;
    }
    #tour-center-menu {
        padding-top: 30px;
        clear: both;
        ul {
            margin: 0;
            padding: 0;
            @media (max-width: 1000px) {
                text-align: center;
            }
            a {
                color: #333;
                font-family: $libreBaskerville;
                font-size: 28px;
                display: inline-block;
                @media (max-width: 1000px) {
                    width: 24.6%;
                    font-size: 24px;
                }
                @media (max-width: 800px) {
                    width: 24.5%;
                    font-size: 19px;
                }
                @media (max-width: 600px) {
                    width: 100%;
                    margin-bottom: 5px;
                    font-size: 25px;
                }
                &:hover {
                    text-decoration: none;
                }
                li {
                    width: 212px;
                    padding: 12px;
                    background: #d0d0d0;
                    text-align: center;
                    display: inline-block;
                    @media (max-width: 1000px) {
                        width: 100%;
                        padding: 12px 0;
                    }
                    @media (max-width: 800px) {
                        padding: 7px 0;
                    }
                }
                li.active {
                    background: white;
                    border: solid 1px #333;
                    border-bottom: none;
                    color: $black;
                    font-weight: bold;
                    @media (max-width: 600px) {
                        border: none;
                        box-shadow: 0 0 2px #000 inset;
                    }
                }
            }
            li.itineraries {
                width: 212px;
                margin-right: 24px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                @media (max-width: 1000px) {
                    width: 185px;
                    margin-right: 0;
                }
                @media (max-width: 800px) {
                    width: 135px;
                }
                @media (max-width: 600px) {
                    width: 100%;
                }
                ul {
                    a li {
                        display: inline-block;
                    }
                    a.itinerary-date {
                        font-size: 17px;
                        display: none;
                        li {
                            width: auto;
                            margin-bottom: 0;
                            padding: 3px 0 0 10px;
                            border: none;
                            font-weight: bold;
                            text-align: left;
                            display: none;

                            margin-top: 5px;
                            padding: 5px 10px 3px 10px;
                            background: #d0d0d0;
                            border-radius: 8px;
                            color: white;
                        }
                        li.active {
                            border: none;
                            background: $gold;
                        }
                    }
                }
            }
            li.itineraries.active {
                background: white;
                border: solid 1px #333;
                border-bottom: none;
                color: $black;
                @media (max-width: 600px) {
                    border: none;
                    box-shadow: 0 0 2px #000 inset;
                }
            }
        }
    }
    #tour-content {
        width: 100%;
        height: auto !important;
        margin: 0;
        padding: 20px 0 0 0;
        font-family: $libreBaskerville;
        @media (max-width: 600px) {
            width: 100%;
        }
        a {
            color: $darkgold;
            word-wrap: break-word;
        }
        section {
            margin-bottom: 50px;
            padding-bottom: 30px;
            border-bottom: solid 5px #e6e6e6;
        }
        h2 {
            margin-top: 20px;
            font-size: 30px;
        }
        h3 {
            font-size: 20px;
            @media (max-width: 800px) {
            }
        }
        h4 {
            margin: 10px 0 15px 0;
        }
        p {
            margin-bottom: 12px;
            font-family: $opensans;
        }
        p.red {
            display: none;
        }
        p,
        ul li {
            font-size: 17px;
        }
        ul {
            list-style-type: square;
        }
        iframe {
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        section.testimonials {
            .quote {
                margin: 30px 0;
                border-top: solid 1px #dcdcdc;
                display: none;
                &:first-of-type {
                    border: none;
                    display: block;
                }
                blockquote {
                    p {
                        color: #777;
                        font-size: 25px;
                        font-style: italic;
                        line-height: 35px;
                        &:before,
                        &:after {
                            content: '\201C';
                            margin: 10px 10px -10px 0;
                            color: #d0d0d0;
                            font-family: serif;
                            font-size: 70px;
                            float: left;
                            vertical-align: bottom;
                            vertical-align: -webkit-baseline-middle;
                            vertical-align: -moz-baseline-middle;
                        }
                        &:after {
                            content: '\201D';
                            float: none;
                        }
                    }
                }
            }
            .traveler {
                margin-top: -20px;
                font-size: 20px;
                float: right;
            }
            button {
                padding: 10px 20px;
                background: $gold;
                border: none;
                color: white;
                font-size: 15px;
                font-weight: bold;
            }
            .row.ultrathinLineFull {
                display: none;
            }
        }
        p.meal-key {
            display: block;
        }
        .itinerary-option,
        .section-title {
            margin-bottom: 15px;
            padding: 10px 25px 10px 15px;
            background: #d0d0d0;
            color: #333;
            font-size: 18px;
            font-weight: bold;
            display: none;
            &:hover {
                cursor: pointer;
            }
            .fa {
                margin: -3px 20px 0 0;
                font-size: 25px;
                display: inline-block;
                vertical-align: top;
            }
            .fa-sort-asc {
                margin: 9px 20px -10px 0;
            }
            .status {
                float: right;
                font-style: italic;
            }
            .svg-inline--fa {
                overflow: visible;
                vertical-align: 0;
                margin-left: 5px;
                margin-right: 12px;
            }
        }
        .itinerary {
            .tour-contents-list {
                margin-bottom: 35px;
                img {
                    max-width: 100%;
                    height: auto;
                }
                .tourDetailsSpecialOffer {
                    border-bottom: none;
                }
            }
            button.terms-conditions {
                margin: 0 0 20px 0;
                padding: 10px 15px;
                background: $gold;
                border: none;
                color: white;
                font-size: 18px;
            }
        }
        .itinerary.show {
            display: block !important;
        }
        #GTM-journeys-esri-map {
            width: 400px;
            margin: 0 0 10px 20px;
            float: right;
            position: relative;
            @media (max-width: 800px) {
                width: 300px;
            }
            @media (max-width: 600px) {
                width: 100%;
                margin: 0;
                float: none;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .itinerary-hotel,
        .itinerary-transportation {
            div {
                color: $darkgold;
                font-size: 17px;
                font-weight: bold;
                .fa {
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .tourDetailsSpecialOffer {
            margin: 0;
            padding-bottom: 10px;
            background: none;
            h4,
            p {
                padding: 0;
                color: #d42930;
                font-size: 17px;
                font-weight: normal;
                line-height: 25px;
            }
            h4 {
                margin-bottom: 5px;
                font-weight: bold;
            }
        }
        .tour-pricing {
            h1 {
                font-size: 38px;
            }
            p {
                margin-bottom: 20px;
                font-size: 19px;
            }
            .priceDeparture {
                &:last-of-type {
                    margin-bottom: 30px;
                }
                h3 {
                    padding: 10px 25px 10px 15px;
                    background: #d0d0d0;
                    color: #333;
                    cursor: pointer;
                    @media (max-width: 600px) {
                        padding-right: 10px;
                    }
                    .fa-sort-desc,
                    .fa-sort-asc,
                    svg {
                        margin: -7px 20px 0 0;
                        font-size: 25px;
                        display: inline-block;
                        vertical-align: top;
                        @media (max-width: 600px) {
                            margin-right: 10px;
                        }
                        @media (max-width: 400px) {
                            margin: -7px 5px 0 -5px;
                        }
                    }
                    .fa-sort-asc {
                        margin: 4px 20px -10px 0;
                    }
                    .date,
                    .departure-status {
                        font-size: 18px;
                        display: inline-block;
                        vertical-align: middle;
                        @media (max-width: 800px) {
                            margin-top: 3px;
                            font-size: 12px;
                        }
                        @media (max-width: 600px) {
                            font-size: 10px;
                        }
                        @media (max-width: 400px) {
                            font-size: 8px;
                        }
                    }
                    .departure-status {
                        font-style: italic;
                        float: right;
                    }
                }
                table.tourcost {
                    @media (max-width: 500px) {
                        width: 100%;
                        table-layout: fixed;
                        font-size: 10px;
                    }
                    font-size: 14px;
                    .button {
                        color: white;
                    }
                    .button-ask-question {
                        margin: 10px 0 20px;
                        color: white;
                    }
                }
                .pricing-transportation {
                    h4 {
                        font-weight: bold;
                        font-size: 25px;
                        padding-top: 10px;
                    }
                    p.lightbox {
                        color: #000;
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: underline;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .pricing-deckplan {
                        display: none;
                    }
                }
            }
        }
        .boldLine {
            margin: 40px 0;
        }
        // Experts tab
        .tour-page-experts {
            height: auto !important;
            @media (max-width: 600px) {
                text-align: center;
            }
            .dates {
                margin: 10px 0;
                padding-top: 30px;
                border-top: solid 5px #e6e6e6;
                color: $darkgold;
                font-size: 22px;
                font-weight: bold;
                &:first-of-type {
                    padding-top: 0;
                    border: none;
                }
            }
            img {
                width: 200px;
                margin-top: 20px;
                vertical-align: top;
                @media (max-width: 600px) {
                    margin: 0 auto;
                    float: none;
                }
            }
            .expert {
                width: 700px;
                margin: 10px 0 50px 15px;
                display: inline-block;
                @media (max-width: 1000px) {
                    width: auto;
                }
                h2 {
                    font-size: 30px;
                    a {
                        color: $black;
                    }
                }
                p {
                    font-size: 19px;
                }
            }
        }

        // Tour details tab
        .tour-page-details {
            iframe {
                @media (max-width: 800px) {
                    width: 80%;
                    padding: 0 10%;
                }
            }

            section.reading-list {
                p {
                    color: $gold;

                    a {
                        font-weight: bold;
                    }
                }
            }

            section.reading-list {
                p {
                    color: $gold;

                    a {
                        font-weight: bold;
                    }
                }
            }

            section.transportation {
                img {
                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .cycle-slideshow {
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .cycle-prev,
                    .cycle-next {
                        @media (max-width: 500px) {
                            top: 28%;
                        }
                        @media (max-width: 400px) {
                            top: 35%;
                        }
                    }
                }
            }

            section.insurance {
                display: none;
            }

            section.testimonials {
                .row.quote {
                    margin-bottom: 30px;
                    padding-top: 30px;
                    display: block;
                    &:first-of-type {
                        padding-top: 0;
                    }
                }

                blockquote {
                    p {
                        color: #777;
                        font-size: 25px;
                        font-style: italic;
                        line-height: 35px;
                        &:before,
                        &:after {
                            content: '\201C';
                            margin: 10px 10px -10px 0;
                            color: #d0d0d0;
                            font-family: serif;
                            font-size: 70px;
                            float: left;
                            vertical-align: bottom;
                            vertical-align: -webkit-baseline-middle;
                            vertical-align: -moz-baseline-middle;
                        }
                        &:after {
                            content: '\201D';
                            float: none;
                        }
                    }
                }
                .traveler {
                    margin-top: -30px;
                    font-size: 20px;
                    float: right;
                }
                .row.ultrathinLineFull {
                    display: none;
                }
            }
            section {
                padding-bottom: 0;
                border-bottom: none;
            }
            .detail-section {
                padding-top: 20px;
                display: none;
                h3 {
                    margin-bottom: -10px;
                }
                h4 {
                    margin-top: 30px;
                    padding-top: 30px;
                    border-top: solid 1px #dcdcdc;
                    font-size: 20px;
                    &:first-of-type {
                        margin-top: 0;
                        padding-top: 0;
                        border: none;
                    }
                }
                .tour-accommodations {
                    br,
                    p:empty {
                        display: none;
                    }
                }
                .transport-slideshow {
                    max-width: 600px;
                    @media (max-width: 800px) {
                        max-width: none;
                        height: 42vw;
                    }
                    .cycle-prev,
                    .cycle-next {
                        top: 35%;
                        svg {
                            @media (max-width: 1000px) {
                                font-size: 20px;
                            }
                            @media (max-width: 800px) {
                                font-size: 5vw;
                            }
                        }
                    }
                }
            }
            .detail-section.show {
                display: block !important;
            }
        }
    }
    .related-tours {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 45px;
        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        h1 {
            margin: 10px 0;
            font-size: 35px;
        }
        h2 {
            margin-bottom: 20px;
            font-size: 1.4rem;
        }
        img {
            width: 100%;
            height: auto;
        }
        .boldLine {
            margin: 0;
        }
    }
    .homepage-sidebar {
        .catalog,
        .sidebar-newsletter {
            display: none;
        }
        .socialConnect {
            margin-left: 950px;
            p {
                color: #000;
            }
        }
    }

    // Tour Brochure Form
    form.webtolead {
        h2 {
            font-size: 20px;
            margin-bottom: 15px;
        }

        select {
            height: auto;
            line-height: 32px;
            margin-bottom: 4px;
            padding: 5px 5px 3px 5px;
            font-size: 14px;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
            border-top: 1px solid #e8e8e8; /* borders forces webkit to respect height.  however, firefox loses user agent styles.  */
            border-left: 1px solid #e8e8e8;
            border-right: 1px solid #c5c5c5;
            border-bottom: 1px solid #c5c5c5;
            border-radius: 5px;
            background-color: #f5f5f5;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#fefefe),
                to(#e6e6e6)
            );
            background: -moz-linear-gradient(top, #fefefe, #e6e6e6);
        }

        .field-group .field,
        .newsletter-pref-center .field-group .field {
            float: left;
            width: 48%;
            @media (max-width: 600px) {
                width: auto;
                float: none;
                display: block;
            }
        }

        .field-group .field:last-child,
        .newsletter-pref-center .field-group .field:last-child {
            margin-left: 15px;
            @media (max-width: 600px) {
                margin-left: 0;
            }
        }

        label,
        .newsletter-pref-center label {
            display: block;
            font-weight: bold;
        }

        div.emaillookup input[type='email'] {
            width: 60% !important;
            margin: 2px 0 5px 0;
            padding: 4px 5px;
            font-size: 15px;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
            border: 1px solid #959595;
        }

        .last {
            margin-left: 20px;
        }

        input[type='text'],
        input[type='email'],
        .newsletter-pref-center input[type='text'],
        .newsletter-pref-center input[type='email'] {
            width: 97% !important;
            margin: 2px 0 5px 0;
            padding: 4px 5px;
            font-size: 15px;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
            border: 1px solid #959595;
        }

        .field-group .city {
            width: 30%;
            margin-right: 20px;
        }

        .field-group .state {
            width: 41%;
        }

        .field-group .zipcode {
            width: 22%;
        }

        input[type='email'] {
            width: 97% !important;
        }

        .newsletter-pref-center input[type='text'],
        .newsletter-pref-center input[type='email'] {
            width: 96% !important;
        }

        input:required,
        .newsletter-pref-center input:required {
            border: 1px solid #ccc;
        }

        textarea,
        .newsletter-pref-center textarea {
            width: 98%;
            height: 80px;
            max-height: 80px;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
            font-size: 15px;
        }

        input[type='checkbox'],
        .newsletter-pref-center input[type='checkbox'] {
            float: left;
            margin-right: 6px;
            margin-bottom: 20px;
            margin-top: 4px;
        }

        button,
        .newsletter-pref-center button {
            margin: 20px 0;
        }

        h2,
        form#newsletter-form h1,
        .newsletter-pref-center h2 {
            margin-top: 20px;
            font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                sans-serif;
        }

        ul.errorlist li {
            font-weight: bold;
            color: #c00;
        }

        button.btn.btn-secondary {
            border: 1px solid #000;
            padding: 6px 20px;
            background: #000;
            color: #fff;
            border-radius: 5px;
            background: #3e3e3e;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#3e3e3e),
                to(#111111)
            );
            background: -moz-linear-gradient(top, #3e3e3e, #111111);
            text-transform: uppercase;
            margin-top: 20px;
        }

        button[type='submit'] {
            border-radius: 5px;
            text-transform: uppercase;
            line-height: 1.5;
        }

        div.laster {
            margin-left: 15px;
        }
    }
}

/* ----------------------------------------
* Lightbox
* ----------------------------------------*/
.tour-page {
    .deckplanImage {
        width: 400px;
        @media (max-width: 1000px) {
            width: 100%;
        }
    }
    #GTM-journeys-esri-map,
    .deckplanImage,
    .pricing-deckplan {
        position: relative;

        button.fullscreen,
        button.print {
            padding: 5px 7px;
            background: $black;
            border: none;
            border-radius: 3px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            top: 13px;
            right: 9px;
            @media (max-width: 1000px) {
                display: none !important;
            }
            &:hover {
                cursor: pointer;
            }
        }
        button.print {
            width: 80px;
            margin-top: 3.5%;
            left: 37%;
            display: none;
        }
        button.fullscreen.topleft {
            left: 9px;
            right: auto;
        }
        button.fullscreen.bottomleft {
            top: auto;
            left: 9px;
            right: auto;
            bottom: 13px;
        }
        button.fullscreen.bottomright {
            top: auto;
            bottom: 13px;
        }
        .fa-times {
            padding: 10px 15px;
            border: solid 5px #dcdcdc;
            border-radius: 100%;
            box-shadow: 0 0 10px #333;
            color: #dcdcdc;
            font-size: 40px;
            text-shadow: 0 0 10px #000;
            position: fixed;
            top: 140px;
            right: 11.3%;
            display: none;
            @media (max-width: 1200px) {
                top: 130px;
                right: 10.5%;
            }
            @media (min-width: 1500px) {
                top: 160px;
                right: 19.5%;
            }
            &:hover {
                color: white;
                border: solid 5px white;
                cursor: pointer;
            }
        }
        iframe {
            width: 100%;
            position: relative;
            z-index: 9;
        }
    }
    #GTM-journeys-esri-map.fullScreen,
    .deckplanImage.fullScreen,
    .pricing-deckplan {
        width: 100% !important;
        margin: 0 !important;
        padding: 5% 15%;
        background: rgba(0, 0, 0, 0.8);
        position: fixed !important;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 998;
        @media (min-width: 1500px) {
            width: 80% !important;
            padding: 5% 22%;
        }
    }
    .deckplanImage.fullScreen,
    .pricing-deckplan {
        width: 100% !important;
        overflow-y: scroll;
        padding: 0;
        padding-top: 1%;
        text-align: center;
        @media (max-width: 500px) {
            top: 50px;
            padding-top: 5%;
        }
        button.fullscreen {
            display: none;
        }
        button.print {
            margin: 3.5% auto 0;
            left: 0;
            right: 0;
            display: block;
        }
        .fa-times {
            background-color: black;
            top: 128px;
            right: 12.3%;
            display: block;
            @media (max-width: 500px) {
                top: 70px;
                right: 5%;
                font-size: 20px;
                padding: 5px 10px;
                border: 2px solid #dcdcdc;
            }
        }
    }
    .pricing-deckplan img {
        width: 50%;
        @media (max-width: 500px) {
            width: 80% !important;
        }
    }
}

/* ----------------------------------------
* Tour Cost
* ----------------------------------------*/

table.tourcost {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    font-size: 14px;
    th {
        text-align: left;
        padding: 4px 6px;
    }
    tr.priceHeading {
        border-bottom: 1px solid #ccc;
        font-weight: bold;
        white-space: nowrap;
    }
    td {
        padding: 4px 6px;
    }
    .right {
        text-align: right;
    }
}

.spe,
.occ,
.ava,
.pri {
    width: 25%;
}

.ava {
    text-align: right !important;
}

tr.priceCategory {
    background: rgb(244, 231, 195);
}

div.priceDeparture h3 {
    background: #f4f4f4;
    padding: 4px 6px;
    font-size: 14px !important;
    font-weight: bold !important;
}
.row h2,
.row h3 {
    clear: both;
}

.books-wrapper {
    width: 146px;
    height: 410px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 16px;
    margin-right: 6px;
    margin-left: 6px;
    padding: 15px;
    position: relative;
    @include box-shadow(0 0 15px #c0c0c0);
    text-align: left;
    display: inline-block;
    vertical-align: top;
    @include transition(all 0.5s);
    @media (max-width: 500px) {
        width: 130px;
    }
    @media (max-width: 400px) {
        width: 95px;
        height: 415px;
    }
    &:hover {
        cursor: pointer;
        @include box-shadow(0 0 25px #888);
    }
    .book-image-wrapper {
        width: 100%;
        height: 260px;
        position: relative;
        overflow: hidden;
        @media (max-width: 500px) {
            height: 200px;
        }
        @media (max-width: 400px) {
            height: 145px;
        }
        img {
            width: 100%;
            position: absolute;
            bottom: 0;
        }
    }
    .book-title {
        margin: 10px 0 15px 0;
        font-size: 14px;
        overflow: hidden;
        @media (max-width: 400px) {
            font-size: 14px;
            line-height: 19px;
        }
    }
    .book-author {
        display: none;
    }
    .book-description {
        display: none;
    }
    .buy-options a {
        background: #000;
        padding: 5px;
        color: #fff !important;
    }
}

.button-readinglist {
    padding: 7px 15px;
    background: $darkgold;
    border: none;
    border-radius: 5px;
    color: #fff;
    float: right;
    font-size: 20px;
    text-transform: none;
    a {
        color: #fff !important;
    }
}

.reservation-button-gold {
    color: #fff !important;
    border-radius: 5px;
    background: #deb659;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#deb659),
        to(#ad872e)
    );
    background: -moz-linear-gradient(top, #deb659, #ad872e);
    padding: 5px 0;
    margin: 20px 0;
    word-wrap: break-word;
    font-size: 11px;
    font-weight: bold;
    width: 135px;
    height: 27px;
    line-height: 2.4;
    cursor: pointer;
    display: block;
    border: none;
    outline: none;
    text-align: center;
    @media (max-width: 400px) {
        font-size: 10px;
        padding: 2px 0;
    }
    &:hover {
        color: #fff;
        text-decoration: none;
        background: #b69b4e;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#b69b4e),
            to(#7d672d)
        );
        background: -moz-linear-gradient(top, #b69b4e, #7d672d);
    }
}
