footer {
    border-top: 1px solid #8a8a8a;
    .footerNav {
        max-width: 1300px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 70px;

        @media (max-width: 1200px) {
            padding: 0 20px;
            gap: 20px;
        }

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .footerNavItem {
            h4 {
                font-family: $libreBaskerville;
                font-size: 1.1rem;
                margin: 20px 0;
                font-weight: 700;
                text-transform: uppercase;
                @media (max-width: 1000px) {
                    margin-bottom: 5px;
                }
            }
            p.spacing {
                margin-top: 30px;
            }
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    font-family: $opensans;
                    font-size: 1.1rem;
                    font-weight: 400;
                    line-height: 1.8rem;
                    a {
                        font-family: $opensans;
                        font-size: 1.1rem;
                        font-weight: 400;
                        border-bottom: 0;
                    }
                }
            }
            .footerLogo {
                width: 100%;
                height: auto;
                margin-top: 20px;
                @media (max-width: 1000px) {
                    width: 250px;
                }
            }
            .travelInsurance {
                padding-bottom: 20px;
                border-bottom: 5px solid #ccc;
                font-size: 1.1rem;
                display: grid;
                grid-template-columns: 70px 1fr;
                grid-gap: 10px;
                align-items: top;
                @media (max-width: 1000px) {
                    width: 220px;
                    margin: auto;
                    text-align: left;
                }
                p {
                    margin-top: 0;
                }
                .insuranceIcon {
                    width: 65px;
                    color: #fff;
                    border-radius: 5px;
                    background: #deb659;
                    background: -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(#deb659),
                        to(#ad872e)
                    );
                    background: -moz-linear-gradient(top, #deb659, #ad872e);
                    display: block;
                    text-align: center;
                    padding: 10px 0;
                    img {
                        width: 40px;
                        height: auto;
                    }
                }
            }
        }
    }
    .footerLinks {
        margin-top: 30px;
        padding: 25px 15px 200px;
        border-top: 1px solid #8a8a8a;
        font-family: $opensans;
        font-size: 0.75rem;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
        p {
            font-family: $opensans;
            font-size: 0.85rem;
            font-weight: 400;
            line-height: 24px;
        }
        span {
            padding: 0 10px;
            @media (max-width: 1300px) {
                padding: 0 5px;
            }
            @media (max-width: 1200px) {
                display: block;
            }
        }
        #ot-sdk-btn.ot-sdk-show-settings,
        #ot-sdk-btn.optanon-show-settings {
            @media (max-width: 1300px) {
                padding: 0.3em 0.8em;
            }
        }
    }
    .destination,
    .tourTypes,
    .worryFree {
        padding-bottom: 1px;
    }
    ul.disc {
        padding-left: 0;
        list-style: none;
    }
    li {
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 17px;
    }
    .footerContainer {
        .row {
            max-width: 1030px;
            .five {
                width: 26.25%;
            }
            .five.columns .destination {
                @media #{$break800} {
                    display: none;
                }
            }
            .footerCenter {
                width: 25%;
                margin: 0 60px;
                @media #{$break1000} {
                    width: 35%;
                    margin: 0 20px;
                }
                @media #{$break800} {
                    display: none;
                }
            }
            .footer-about {
                display: none;
                @media #{$break800} {
                    display: block;
                }
            }
            .five,
            .footerCenter {
                @media #{$break800} {
                    width: 96%;
                    margin: 10px 2%;
                    text-align: center;
                }
            }
        }
        p {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
        }
        h3 {
            font-size: 30px;
            letter-spacing: -1px;
        }
    }
    .worryFree {
        min-height: 140px;
        padding: 20px 0;
        margin-bottom: 40px;
        border-bottom: solid 1px #666;
        text-align: left;
        @media #{$break800} {
            margin-bottom: 0;
            border-bottom: none;
            text-align: center;
        }
    }
    .bellHop {
        width: auto;
        @media #{$break800} {
            float: none;
            display: block;
        }
    }
    .button.square {
        width: 65px;
        height: 65px;
        @media #{$break800} {
            margin: 10px auto;
        }
        img {
            width: 40px;
        }
    }
    .footer-logo {
        width: 240px;
    }
    .goldBG {
        background: #9c7928;
        margin: 0;
        padding: 10px;
        font-weight: bold;
        a:hover {
            padding: 12px 0 0 0;
            color: #fff;
        }
    }
    .whiteText {
        margin-bottom: -40px;
        padding: 0 0 12px 0;
    }
    .footerLinks.center {
        @media #{$break1000} {
            padding-bottom: 0;
        }
    }
    .footerLinks #div-gpt-ad-1419362312109-0,
    .footerLinks .affiliates-nav {
        display: none;
    }
    .plan {
        @media #{$break800} {
            width: auto;
            float: none;
        }
    }
}

#footer_email_bar {
    @media #{$break1000} {
        display: none !important;
    }
}
