#main.tripfinder {
    padding: 20px !important;
    input:checked + .slider {
        background-color: $gold;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $gold;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        @media #{$break1200} {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
        }
    }
    .containerFixed {
        max-width: 1300px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 360px 1fr;
        grid-gap: 30px;
        @media (max-width: 1000px) {
            display: block;
        }
        .row .columns .row.tailor-made-promo .twoOfThree.columns {
            p,
            h2 {
                max-width: 80%;
                @media #{$break400} {
                    padding: 0 15px;
                }
            }
        }
    }
    .slideshow {
        max-width: 940px;
        height: 419px;
        margin: auto;
        position: relative;
        overflow: hidden;
        @media (max-width: 1400px) {
            height: 31vw;
        }
        @media (max-width: 1200px) {
            height: 29vw;
        }

        @media (max-width: 1000px) {
            height: 43vw;
        }

        .cycle-prev,
        .cycle-next {
            width: 50px;
            height: 80px;
            background: rgba(255, 255, 255, 0.6);
            opacity: 0.6;
            text-align: center;
            position: absolute;
            top: 36%;
            z-index: 800;
            cursor: pointer;

            @media (max-width: 1000px) {
                width: 5vw;
                height: 8.5vw;
                top: 12vw;
            }

            svg {
                margin-top: 20px;
                color: #fff;
                font-size: 45px;
                text-shadow: 0 0 5px #999;

                @media (max-width: 1000px) {
                    margin-top: 2vw;
                    font-size: 5vw;
                }
            }
        }

        .cycle-next {
            right: 0;
        }

        .slide {
            overflow: hidden;
            position: absolute;
            bottom: 0;

            img {
                width: 100%;
                height: 100%;
            }

            /* Ribbon banners */
            .ribbon {
                padding: 2px 4px;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 7px;
                font-size: 15px;
                position: absolute;
                margin: 0;
                padding: 0;
                top: 0;
                left: 0;
                display: block;
            }

            .ribbon-orange,
            .ribbon-orange .banner {
                background: #fbb450;
            }
            .ribbon-red,
            .ribbon-red .banner {
                background: #863a3c;
                color: #fff;
            }
            .ribbon-yellow,
            .ribbon-yellow .banner {
                background: #ffd95e;
            }

            .ribbon-orange,
            .ribbon-red,
            .ribbon-yellow {
                background: none;
            }

            .ribbon .banner {
                width: 180px;
                height: 24px !important;
                padding: 10px 0 0 0;
                left: -39px;
                top: 33px;
                color: #fff;
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-font-smoothing: antialiased;
                float: left;
                position: relative;
                display: block;
                @media (max-width: 600px) {
                    width: 100px;
                    height: 14px !important;
                    border-radius: 0 0 5px 0;
                    transform: rotate(0deg);
                    top: 0;
                    left: 0;
                }
            }
            .ribbon .banner .text {
                min-height: auto;
                margin-right: 10px;
                font-size: 15px;
                text-align: center;
                line-height: 10px;
                @media (max-width: 1000px) {
                    font-size: 1.2vw;
                }
                @media (max-width: 600px) {
                    margin-right: 0;
                    font-size: 10px;
                    line-height: 4px;
                }
            }
            .ribbon .banner:before {
                left: -76px;
            }
            .ribbon .banner .text:before,
            .ribbon .banner .text:after {
                height: 30px;
            }

            .text-wrapper {
                padding: 12px 41px;
                background-color: rgba(0, 0, 0, 0.7);
                color: white;
                font-family: 'FuturaBT-Book', 'Century Gothic', Verdana, Geneva,
                    sans-serif;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 5px;
                z-index: 9;

                @media (max-width: 600px) {
                    padding: 1.5vw 3vw 2vw;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }

                .title {
                    font-size: 2rem;
                    line-height: 1.3;

                    @media (max-width: 600px) {
                        font-size: 4vw;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .description {
                    font-size: 0.95rem;
                    line-height: 1.5;

                    @media (max-width: 600px) {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }

    .row:first-of-type {
        //max-width: 905px;
        //padding-top: 0;
        //display: inline-block;
        // @media #{$break1400} {
        //     max-width: 68%;
        //     text-align: left;
        // }
        // @media #{$break1300} {
        //     max-width: 66%;
        // }
        // @media #{$break1200} {
        //     max-width: 68%;
        // }
        // @media #{$break1100} {
        //     max-width: 64%;
        // }
        // @media #{$break1000} {
        //     max-width: 60%;
        // }
        // @media #{$break900} {
        //     max-width: none;
        // }
    }
    .row.thinLineFullGray:first-of-type {
        display: none;
    }
    .all-filter {
        margin-right: 15px;
        padding: 2px 15px;
        border: solid 2px $gold;
        color: $gold;
        font-size: 16px;
        text-align: center;
        text-transform: none;
        float: right;
        display: none;
        @media #{$break1100} {
            font-size: 14px;
        }
        &:hover {
            background: $gold;
            color: $black;
        }
    }
    .all-filter.clicked {
        color: $black;
        background: $gold;
    }
    form#tripfinder-form {
        padding: 0;
        .facet {
            margin: 10px 0 40px;
            overflow: inherit;
            @media #{$break900} {
                margin: 10px 0;
            }
            @media #{$break400} {
                margin: 5px 0 !important;
            }
        }
        .facet.hidden {
            display: none;
        }
        .facet.collapsed {
            margin-bottom: 10px;
            overflow: hidden;
        }
        .facet-label {
            width: auto;
            padding: 12px 20px;
            background: #464646;
            color: $white;
            font-size: 20px;
            text-transform: uppercase;
            &:hover {
                cursor: pointer;
            }
            @media #{$break1100} {
                padding: 6px 15px 5px;
            }
            svg {
                color: $gold;
                font-size: 24px;
                float: right;
                position: relative;
                margin-top: -4;
            }
        }
        ul {
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: left;
            overflow: hidden;
            h3 {
                margin: 1px 0 0;
                font-size: 18px;
                clear: both;
                @media #{$break1200} {
                    font-size: 16px;
                }
                img {
                    width: 70px;
                    margin-right: 20px;
                    vertical-align: middle;
                    @include transition(all 0.1s);
                    @media #{$break1200} {
                        margin-right: 10px;
                    }
                }
                .region-name {
                    max-width: 190px;
                    display: inline-block;
                    vertical-align: middle;
                    color: $black;
                    @media #{$break1200} {
                        max-width: 170px;
                    }
                    @media #{$break900} {
                        max-width: none;
                    }
                    @media #{$break400} {
                        max-width: 170px;
                    }
                }
            }
            li {
                margin: 0 2px 0 3px;
                list-style-type: none;
                position: relative;
                display: inline-block;
                &:first-of-type {
                    margin-top: 10px;
                    @media #{$break900} {
                        margin-top: 0;
                    }
                }
                //&:nth-of-type(odd) {margin-right: 5px}
                @media #{$break900} {
                    width: auto;
                    margin-right: 5px;
                    text-align: center;
                    float: left;
                }
                label {
                    margin: 5px 0;
                    padding: 3px 5px;
                    color: #333;
                    border: solid 2px $gold;
                    font-size: 14px;
                    text-align: center;
                    display: inline-block;
                    @media #{$break1200} {
                        font-size: 12px;
                    }
                    @media #{$break900} {
                        width: auto;
                        margin: 6px 0 1px;
                        padding: 3px 5px;
                    }
                }
                input {
                    width: 97.5%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 999;
                    @media #{$break900} {
                        width: 100%;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                input[type='checkbox']:not(:checked) + label {
                    background: none;
                    border: solid 2px $gold;
                    color: #333;
                }
                input[type='checkbox']:checked + label {
                    background: #464646;
                    border: solid 2px #464646;
                    color: $white;
                }
            }
            li:hover > label,
            input[type='checkbox']:not(:checked):hover + label {
                background: $gold;
            }
            li.selected label {
                background: #464646;
                border: solid 2px #464646;
                color: $white;
            }
            li.selected:hover > label {
                background: $gold;
                border: solid 2px $gold;
            }
            li.lightgray {
                label,
                input[type='checkbox']:not(:checked) + label {
                    background: #ccc;
                    border: solid 2px #ccc;
                }
            }
            li.lightgray:hover {
                label,
                input[type='checkbox']:not(:checked) + label {
                    background: $gold;
                    border: solid 2px $gold;
                }
            }
        }
        ul.destinations {
            h3 {
                @media #{$break400} {
                    padding: 0;
                }
                @media #{$break350} {
                    font-size: 14px;
                }
                img {
                    -webkit-filter: invert(0) sepia(0) brightness(100%);
                    filter: invert(0) sepia(0) brightness(100%);
                }
                img.gold {
                    -webkit-filter: invert(70%) sepia(80%) brightness(160%);
                    filter: invert(70%) sepia(80%) brightness(160%);
                }
            }
            li {
                display: none;
                label {
                    padding: 2px 8px;
                    @media #{$break900} {
                        margin: 3px 0;
                        padding: 3px 5px;
                    }
                }
            }
        }
        ul.departure_date {
            h3 {
                margin: 30px 0 20px;
                color: $black;
                @media #{$break900} {
                    margin: 20px 0;
                }
                .all-filter {
                    margin-top: -8px;
                    margin-right: 8px;
                    padding: 5px 15px;
                    &:hover {
                        color: $white;
                        cursor: pointer;
                    }
                }
                &:last-of-type {
                    @media #{$break900} {
                        margin-top: 30px;
                    }
                }
                .all-filter.clicked {
                    color: $white;
                }
            }
            li {
                width: 55px;
                margin: 0 2px 7px 0;
                position: relative;
                @media #{$break900} {
                    width: auto;
                    margin: 0 4px 9px;
                    float: none;
                }
                &:nth-of-type(6n) {
                    margin: 0 0 7px 0;
                    @media #{$break1200} {
                        margin-right: 3px;
                    }
                    @media #{$break900} {
                        margin: 0 4px 9px;
                    }
                }
                &:nth-of-type(5n) {
                    @media #{$break1200} {
                        margin-right: 0;
                    }
                    @media #{$break900} {
                        margin-right: 4px;
                    }
                }
                label {
                    width: 69%;
                    margin: 0;
                    padding: 5 15px;
                    background: #d9d9d9;
                    border: none;
                    color: #919191;
                    font-size: 15px;
                    font-weight: 700;
                    text-transform: uppercase;
                    @media #{$break900} {
                        width: 35px;
                        padding: 5px 15px;
                    }
                }
                input {
                    width: 97.5%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 999;
                    &:hover {
                        cursor: pointer;
                    }
                }
                input[type='checkbox']:checked + label {
                    background: $gold;
                    border: none;
                    color: $black;
                }
                input[type='checkbox']:not(:checked) + label {
                    background: #d9d9d9;
                    border: none;
                    color: #000;
                }
                input[type='checkbox']:disabled + label,
                input[type='checkbox']:disabled:hover + label {
                    background: #a7a7a7;
                    border: none;
                    color: #777;
                }
            }
            li:hover > label,
            input[type='checkbox']:not(:checked):hover + label {
                background: $gold;
                color: $black;
            }
            li.lightgray:hover {
                label,
                input[type='checkbox']:not(:checked) + label {
                    background: $gold;
                    border: none;
                }
            }
        }
        #backFilters {
            width: 326px;
            padding: 5px 15px;
            border: solid 2px $gold;
            color: $gold;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            position: fixed;
            top: 210px;
            display: none;
            @media #{$break1400} {
                top: 180px;
            }
            @media #{$break1200} {
                width: 266px;
            }
            @media #{$break1000} {
                top: 90px;
            }
            @media #{$break900} {
                display: none;
            }
            &:hover {
                background: $gold;
                color: $white;
            }
        }
    }
    .search-sort-bar {
        margin: 9px 0 15px;
        padding: 12px 20px;
        background: #464646;
        @media #{$break1100} {
            padding: 5px 15px;
        }
        @media #{$break900} {
            margin-top: 0;
        }
        h3 {
            margin: 5px 70px 0 0;
            color: $white;
            font-size: 22px;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            @media #{$break1100} {
                margin-right: 40px;
            }
            @media #{$break1000} {
                margin-right: 20px;
            }
            @media #{$break400} {
                padding: 0;
            }
        }
        .sort-options {
            margin-top: 4px;
            float: right;
            @media #{$break600} {
                float: none;
            }
        }
        .sort-by {
            margin-right: 15px;
            color: $white;
            font-size: 15px;
            display: inline-block;
            vertical-align: middle;
            @media #{$break600} {
                float: none;
            }
        }
        .sort-option {
            margin-left: 20px;
            color: $gold;
            font-size: 16px;
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            @media (max-width: 1050px) {
                margin-left: 5px;
            }
            @media #{$break1000} {
                margin-left: 10px;
            }
            @media #{$break600} {
                margin-left: 0;
                display: block;
            }
            &:hover {
                cursor: pointer;
            }
            a {
                color: $gold;
            }
            .fa-caret-down,
            .fa-caret-up {
                margin: 1px 0 0 10px;
                font-size: 22px;
                opacity: 0;
                vertical-align: top;
                @media #{$break1000} {
                    margin-left: 5px;
                }
            }
        }
    }
    .sort-keyword {
        margin: 0 10px 10px 0;
        padding: 5px 15px;
        background: #7e7e7e;
        border-radius: 10px;
        color: $white;
        font-size: 16px;
        font-weight: 600;
        //display: inline-block;
        display: none;
        vertical-align: middle;
        &:hover {
            cursor: pointer;
        }
        @media #{$break1100} {
            margin: 0 5px 5px 0;
            padding: 2px 10px;
            font-size: 13px;
        }
        .fa {
            margin: 6px 0 0 8px;
            font-size: 12px;
            vertical-align: top;
            @media #{$break1100} {
                margin: 3px 0 0 4px;
            }
        }
    }
    .row#searchHeader {
        max-width: none;
        h1 {
            #clearFilters {
                margin-right: 0;
                float: right;
                display: block;
                @media #{$break500} {
                    margin-top: 10px;
                    padding: 10px 15px;
                    float: none;
                    display: block;
                }
            }
        }
    }
    .containerFixed .row .columns .row {
        .row.searchResults {
            margin: 8px auto 10px;
        }
        .oneOfThree.columns {
            width: auto;
            margin-right: 30px;
            @media #{$break600} {
                width: 100% !important;
                margin: 0;
            }
            img {
                width: 190px;
                @media #{$break600} {
                    width: 100%;
                }
            }
        }
        .twoOfThree.columns {
            width: 660px;
            @media #{$break1100} {
                width: 600px;
            }
            @media #{$break1000} {
                width: 530px;
            }
            @media #{$break900} {
                width: 100%;
            }
            h2 {
                display: inline-block;
                @media #{$break400} {
                    padding: 0;
                }
            }
            h3 {
                @media #{$break400} {
                    padding: 0;
                }
            }
            p {
                text-align: center;
                font-size: 13px;
                font-weight: 700;
                float: right;
                display: inline-block;
                @media #{$break400} {
                    padding: 0;
                    text-align: left;
                    float: none;
                    display: block;
                }
                .gold-price {
                    color: $gold;
                    font-size: 30px;
                    font-weight: 700;
                    a {
                        color: $gold;
                    }
                }
            }
            p.promo-description {
                font-weight: 500;
                text-align: left;
                float: none;
            }
            p.price-request {
                @media #{$break1000} {
                    margin-bottom: 20px;
                    text-align: left;
                    float: none;
                    display: block;
                }
            }
            a.learn-more {
                margin: 10px 0 0;
                padding: 4px 8px;
                border: solid 2px $gold;
                color: #333;
                font-weight: lighter;
                &:hover {
                    background: $gold;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            ul {
                .see-more {
                    width: 74px;
                    margin: 10px 0 0 -18px;
                    padding: 1px 5px;
                    border: solid 2px #d9b859;
                    color: #444;
                    font-size: 12px;
                    text-align: center;
                    display: none;
                    cursor: pointer;
                    .fa-plus {
                        margin-right: 5px;
                        color: $gold;
                        font-size: 12px;
                    }
                    &:hover,
                    &:hover > .fa-plus {
                        background: $gold;
                        color: $white;
                    }
                }
            }
            .thinLineFullGray {
                @media #{$break900} {
                    display: block;
                }
            }
        }
    }
}
