.tourList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
    @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }
    h3 {
        border-bottom: 4px solid #d1d1d1;
        padding-bottom: 15px;
        a {
            color: $black;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    p {
        a {
            color: $darkgold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
